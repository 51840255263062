@media all and (max-width:767px){

  .widget-product-list-container {
    .owl-controls{
      .owl-nav{
        .owl-prev{
          left: -35px !important;
        }
        .owl-next{
          right: -35px !important;
        }
      }
    }
  }

}