.clear-both{
  clear: both;
}

.morecontent span {
  display: none;
}
.morelink {
  display: block;
}
.content-container{
  max-width: 1140px;
  width: 100%;
  margin: auto auto;
}

.grey-background-container{
  background-color: #F9F9F9;
}

#totop{
  border-radius: 0px 0px 0px 0px !important;
}

.at-resp-share-element .at-share-btn .at-label{
  font-family: 'titillium_webregular' !important;
}

.sidebar .mpblog-search input{
  font-family: 'titillium_webregular' !important;
}

.page-wrapper{

  >.breadcrumbs{
    background-color: #FFFFFF;
    margin: 15px auto 15px auto;

    a,strong{
      color: #9B9D9D;
      font-family: 'titillium_webregular';
      font-size: 14px;
      font-weight: 500;

      &:hover{
        color: #9B9D9D;
      }
    }
  }

  .breadcrumbs .item:not(:last-child):after{
    color: #9B9D9D;
    margin: 0px 5px;
  }
}

/* POSITION_LEFT_BOTTOM */
.label-position0 {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 3;
}

/* POSITION_RIGHT_BOTTOM */
.label-position1 {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 3;
}

/* POSITION_RIGHT_TOP */
.label-position2 {
  position: absolute;
  right: 10px;
  top: 30px;
  z-index: 3;
}

/* POSITION_LEFT_TOP */
.label-position3 {
  position: absolute;
  left: 10px;
  top: 30px;
  z-index: 3;
}

.label-box{
  width: 100px;
  height: 25px;
  background-color: #2CCA44;
}

.label-description{
  color: #fff;
  font-family: 'titillium_webregular';
  text-transform: uppercase;
  line-height: 25px;
  font-size: 11px;
  margin: 2px 0px 2px 0px;
  background-size: 100%;
  background-position: 50% 50%;
  height: 25px;
}

.clear-both{
  clear: both;
}