@font-face {
  font-family: 'titillium_websemibold_italic';
  src: url('../fonts/titilliumweb-semibolditalic.eot');
  src: url('../fonts/titilliumweb-semibolditalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-semibolditalic.woff2') format('woff2'),
  url('../fonts/titilliumweb-semibolditalic.woff') format('woff'),
  url('../fonts/titilliumweb-semibolditalic.ttf') format('truetype'),
  url('../fonts/titilliumweb-semibolditalic.svg#titillium_websemibold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_websemibold';
  src: url('../fonts/titilliumweb-semibold.eot');
  src: url('../fonts/titilliumweb-semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-semibold.woff2') format('woff2'),
  url('../fonts/titilliumweb-semibold.woff') format('woff'),
  url('../fonts/titilliumweb-semibold.ttf') format('truetype'),
  url('../fonts/titilliumweb-semibold.svg#titillium_websemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_webregular';
  src: url('../fonts/titilliumweb-regular.eot');
  src: url('../fonts/titilliumweb-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-regular.woff2') format('woff2'),
  url('../fonts/titilliumweb-regular.woff') format('woff'),
  url('../fonts/titilliumweb-regular.ttf') format('truetype'),
  url('../fonts/titilliumweb-regular.svg#titillium_webregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_weblight_italic';
  src: url('../fonts/titilliumweb-lightitalic.eot');
  src: url('../fonts/titilliumweb-lightitalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-lightitalic.woff2') format('woff2'),
  url('../fonts/titilliumweb-lightitalic.woff') format('woff'),
  url('../fonts/titilliumweb-lightitalic.ttf') format('truetype'),
  url('../fonts/titilliumweb-lightitalic.svg#titillium_weblight_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_weblight';
  src: url('../fonts/titilliumweb-light.eot');
  src: url('../fonts/titilliumweb-light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-light.woff2') format('woff2'),
  url('../fonts/titilliumweb-light.woff') format('woff'),
  url('../fonts/titilliumweb-light.ttf') format('truetype'),
  url('../fonts/titilliumweb-light.svg#titillium_weblight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_webitalic';
  src: url('../fonts/titilliumweb-italic.eot');
  src: url('../fonts/titilliumweb-italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-italic.woff2') format('woff2'),
  url('../fonts/titilliumweb-italic.woff') format('woff'),
  url('../fonts/titilliumweb-italic.ttf') format('truetype'),
  url('../fonts/titilliumweb-italic.svg#titillium_webitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_webXLtIt';
  src: url('../fonts/titilliumweb-extralightitalic.eot');
  src: url('../fonts/titilliumweb-extralightitalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-extralightitalic.woff2') format('woff2'),
  url('../fonts/titilliumweb-extralightitalic.woff') format('woff'),
  url('../fonts/titilliumweb-extralightitalic.ttf') format('truetype'),
  url('../fonts/titilliumweb-extralightitalic.svg#titillium_webXLtIt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_webextralight';
  src: url('../fonts/titilliumweb-extralight.eot');
  src: url('../fonts/titilliumweb-extralight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-extralight.woff2') format('woff2'),
  url('../fonts/titilliumweb-extralight.woff') format('woff'),
  url('../fonts/titilliumweb-extralight.ttf') format('truetype'),
  url('../fonts/titilliumweb-extralight.svg#titillium_webextralight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_webbold_italic';
  src: url('../fonts/titilliumweb-bolditalic.eot');
  src: url('../fonts/titilliumweb-bolditalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-bolditalic.woff2') format('woff2'),
  url('../fonts/titilliumweb-bolditalic.woff') format('woff'),
  url('../fonts/titilliumweb-bolditalic.ttf') format('truetype'),
  url('../fonts/titilliumweb-bolditalic.svg#titillium_webbold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_webbold';
  src: url('../fonts/titilliumweb-bold.eot');
  src: url('../fonts/titilliumweb-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-bold.woff2') format('woff2'),
  url('../fonts/titilliumweb-bold.woff') format('woff'),
  url('../fonts/titilliumweb-bold.ttf') format('truetype'),
  url('../fonts/titilliumweb-bold.svg#titillium_webbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'titillium_webblack';
  src: url('../fonts/titilliumweb-black.eot');
  src: url('../fonts/titilliumweb-black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-black.woff2') format('woff2'),
  url('../fonts/titilliumweb-black.woff') format('woff'),
  url('../fonts/titilliumweb-black.ttf') format('truetype'),
  url('../fonts/titilliumweb-black.svg#titillium_webblack') format('svg');
  font-weight: normal;
  font-style: normal;
}