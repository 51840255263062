.phone-icon{
  background-image: url("../images/icons/phone.svg");
  display: inline-block;
  width: 15px;
  height: 14px;
  right: 5px;
  top: 1px;
  position: relative;
}

.phone-icon-footer{
  background-image: url("../images/icons/phone_footer.svg");
  display: inline-block;
  width: 15px;
  height: 14px;
  right: 5px;
  top: 7px;
  position: relative;
}

.evolpe-icon{
  background-image: url("../images/icons/envelope.svg");
  display: inline-block;
  width: 15px;
  height: 14px;
  right: 5px;
  top: 1px;
  position: relative;
}

.evolpe-icon-footer{
  background-image: url("../images/icons/envelope_footer.svg");
  display: inline-block;
  width: 15px;
  height: 14px;
  right: 5px;
  top: 7px;
  position: relative;
}

.footer-icon-facebook{
  background-image: url("../images/icons/fb.svg");
  display: inline-block;
  width: 50px;
  height: 50px;
}

.footer-icon-tweeter{
  background-image: url("../images/icons/tweet.svg");
  display: inline-block;
  width: 50px;
  height: 50px;
}

.footer-icon-linkedin{
  background-image: url("../images/icons/in.svg");
  display: inline-block;
  width: 50px;
  height: 50px;
}

.footer-icon-youtube{
  background-image: url("../images/icons/ut.svg");
  display: inline-block;
  width: 50px;
  height: 50px;
}


.language {
  .view-main_de_website_store_view .language-store-flag,
  .view-main_de_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/niemcy.svg");
  }

  .view-main_be_nl_website_store_view .language-store-flag,
  .view-main_be_nl_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/belgia.svg");
  }

  .view-main_be_be_website_store_view .language-store-flag,
  .view-main_be_nl_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/belgia.svg");
  }

  .view-main_uk_website_store_view .language-store-flag,
  .view-main_uk_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/wielkabrytania.svg");
  }

  .view-main_pt_website_store_view .language-store-flag,
  .view-main_pt_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/portugalia.svg");
  }

  .view-main_es_website_store_view .language-store-flag,
  .view-main_es_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/hiszpania.svg");
  }

  .view-main_be_fr_website_store_view .language-store-flag,
  .view-main_be_fr_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/belgia.svg");
  }

  .view-main_fr_website_store_view .language-store-flag,
  .view-main_fr_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/francja.svg");
  }

  .view-main_be_website_store_view .language-store-flag,
  .view-main_be_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/belgia.svg");
  }

  .view-main_be_nl_website_store_view .language-store-flag,
  .view-main_be_nl_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/belgia.svg");
  }

  .view-main_nl_website_store_view .language-store-flag,
  .view-main_nl_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/holandia.svg");
  }

  .view-main_at_website_store_view .language-store-flag,
  .view-main_at_website_store_view .language-selector-flag {
    background-image: url("../images/language_flags/austria.svg");
  }

  .view-main_pl_website_store_view .language-store-flag,
  .view-main_pl_website_store_view .language-selector-flag,
  .language-flag-default,
  .view-default .language-selector-flag {
    background-image: url("../images/language_flags/polska.svg");
  }
}

.language-selector-flag {
  background-size: cover;
  display: inline-block;
  width: 26px;
  height: 18px;
  float: right;
  position: relative;
  top: -3px;
}

.language-store-flag{
  background-size: cover;
  display: inline-block;
  width: 26px;
  height: 18px;
  float: right;
  position: relative;
  top: -2px;
  margin-left: 5px;
}

.service-page-icon-facebook{
  background-image: url("../images/icons/fb.svg");
  display: inline-block;
  width: 40px;
  height: 40px;
}

.service-page-icon-tweeter{
  background-image: url("../images/icons/tweet.svg");
  display: inline-block;
  width: 40px;
  height: 40px;
}

.service-page-icon-linkedin{
  background-image: url("../images/icons/in.svg");
  display: inline-block;
  width: 40px;
  height: 40px;
}

.service-page-icon-youtube{
  background-image: url("../images/icons/ut.svg");
  display: inline-block;
  width: 40px;
  height: 40px;
}

.contact-form-name-icon{
  background-image: url("../images/icons/5.svg");
  background-size: 120%;
  background-position: 50% 50%;
  position: relative;
  right: -1px;
}

.contact-form-email-icon{
  background-image: url("../images/icons/4.svg");
  background-size: 120%;
  background-position: 50% 50%;
  position: relative;
  right: -1px;
}

.contact-form-phone-icon{
  background-image: url("../images/icons/2.svg");
  background-size: 120%;
  background-position: 50% 50%;
  position: relative;
  right: -1px;
}

.contact-form-company-icon{
  background-image: url("../images/icons/3.svg");
  background-size: 120%;
  background-position: 50% 50%;
  position: relative;
  right: -1px;
}

.contact-form-attachment-icon{
  background-image: url("../images/icons/1.svg");
  background-size: 120%;
  background-position: 50% 50%;
  position: relative;
  right: 0px;
}

.download-pdf-specification-button-background{
  background-image: url("../images/icons/pdf_icon.svg");
  background-repeat: no-repeat;
  background-size: 210%;
  background-position: 50% 50%;
}

.form-info-icon{
  background-image: url("../images/icons/icon_info.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.mini-logo-rotom-google{
  display: block;
  background-image: url("../images/logo.svg");
  width: 60px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  margin: auto auto;
}