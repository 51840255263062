.footer-top{
  background-color: #363636;
  padding: 40px 0px 0px 0px;

  .pre-content{
    display: block;
    float: left;
  }

  .content{
    display: block;
    float: right;
  }

  .container{
    border-bottom: 2px solid #737171;
    padding-bottom: 30px;
  }

  .block.newsletter{
    width: 75% !important;
    float: left;
    margin-bottom: 0px;

    .field{
      .control{
        &:before{
          display: none;
        }
      }
    }

    .form.subscribe{
      width: 390px;
    }

    input{
      border-radius: 0px;
      height: 50px;
      padding-left: 15px;
      font-family: 'titillium_webregular';
      box-shadow: none;
      line-height: 28px;

      &::-webkit-input-placeholder {
        color: #CECECE;
        font-size: 18px;
        line-height: normal;
        font-family: 'titillium_websemibold';
      }
      &::-moz-placeholder {
        color: #CECECE;
        font-size: 18px;
        line-height: normal;
        font-family: 'titillium_websemibold';
      }
      &:-ms-input-placeholder {
        color: #CECECE;
        font-size: 18px;
        line-height: normal;
        font-family: 'titillium_websemibold';
      }
      &:-moz-placeholder {
        color: #CECECE;
        font-size: 18px;
        line-height: normal;
        font-family: 'titillium_websemibold';
      }
      &::placeholder {
        color: #CECECE;
        font-size: 18px;
        line-height: normal;
        font-family: 'titillium_websemibold';
      }
    }

    .action.subscribe{
      background: #6699FF;
      height: 50px;
      border-radius: 0px;
      font-family: 'titillium_weblight';
      text-transform: uppercase;
      margin-left: 0px;
      &:hover {
        transition: all 0.2s ease-in-out;
        background: #003059;
        border: 1px solid #003059;
      }
    }

    .block-title{
      margin: 0px 0px 0px 0px;

      strong{
        font-family: 'titillium_weblight';
        color: #fff;
        text-transform: uppercase;
        font-size: 17px;
      }
    }

    p{
      color: #fff;
      font-size: 16px;
      line-height: 16px;
      font-family: 'titillium_weblight';
    }

    .checkboxes-required-element{
      padding: 0px 5px 0px 0px;
      font-family: 'titillium_webregular';
      color: #A2A4A4;
      font-size: 10px;
    }

    .checkboxes-required-element-error{
      color: #e02b27;
    }
  }


  /*CHECKBOXES START*/

  // CHECKBOXES START

  .checkbox-main-container{
    width: 390px;
    margin: 20px 0px 0px 0px;
  }

  .label-container {
    display: block;
    position: relative;
    padding-left: 22px;
    margin: 2px 0px 2px 0px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .label-container a {
    text-decoration: underline;
    color: #a8a8a8 !important;
    &:hover {
      font-weight: 700;
      background: none;
    }
  }

  .label-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #B0B2B2;
  }

  .label-container:hover input ~ .checkmark {
    background-color: #fff;
  }

  .label-container input:checked ~ .checkmark {
    background-color: #fff;
    border: 1px solid #B0B2B2;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .label-container input:checked ~ .checkmark:after {
    display: block;
  }

  .label-container .checkmark:after {
    left: 5px;
    top: -1px;
    width: 5px;
    height: 14px;
    border: solid #000;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .label-container{
    p{
      font-family: 'titillium_webregular' !important;
      color: #A2A4A4 !important;
      font-size: 12px !important;
      padding: 0px 15px 0px 0px;
      margin-bottom: 0;
    }
  }

  .inputfile{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label{
    border: 1px solid #6699FF;
    background-color: #fff;
    padding: 0;
    color: #6699FF;
    max-width: 80%;
    font-size: 1.25rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    margin-left: 2px;
    width: 95px;
    height: 28px;
  }

  .inputfile + label .contact-form-attachment-icon{
    width: 36px;
    height: 26px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
  }

  .inputfile + label .add-file-span{
    width: 50px;
    font-family: 'titillium_webextralight';
    margin: 0px 0px 0px 8px;
    line-height: 26px;
    font-size: 11px;
  }

  .add-file-2ndspan{
    background-color: #fff;
    display: inline-block;
    height: 28px;
    width: 54%;
    margin: 0px 0px 0px 15px;
    overflow: hidden;
    white-space: nowrap;
    line-height: 27px;
    padding: 0px 0px 0px 10px;
    border: 1px solid #EBEBEB;
    color: #DCDCDC;
  }

  .max-file-size-span{
    font-size: 13px;
    line-height: 27px;
    padding: 0px 0px 0px 15px;
    color: #9F9F9F;
  }

  .checkbox-general-form-error-message{
    margin-top: 0px;
    position: relative;
    display: block;
    top: -2px;
    left: 0px;
    width: 200px;
    font-size: 10px;
    text-align: left;
    color: #e02b27;
    &:before {
      content:" *";
    }
  }

  .checkbox-general-form-error-message + .checkmark{
    border: 1px solid red;
  }

  /*CHECKBOXES END*/

}

div.mage-error[generated] {
  font-size: 10px;
  width: calc(100% + 110px);
}

input.mage-error{
  border-color: #e02b27;
}

.footer-middle{
  background-color: #363636;
  padding: 20px 0px 40px 0px;

  .block{
    .block-title{

      h5{
        color: #fff;
        text-transform: uppercase;
        font-size: 23px;
        font-family: 'titillium_websemibold';
      }
    }

    .block-content{
          .links,.features,.customer-care,.contact-info{
            margin-top: -5px;

            li{
              padding: 5px 0px;
              line-height: 1;
              a,p{
                font-family: 'titillium_webregular';
                color: #C8C8C8;
                font-size: 17px;
              }

              em{
                color: #fff;
                font-size: 24px;
                line-height: 28px;
              }
            }
          }

      .contact-info{
        padding-bottom: 20px;

        li{
          padding: 0px 0px;

          &:first-of-type{
            padding-top: 5px;
          }
        }

        &:nth-of-type(2){
            p{
              padding-left: 10px;
            }
        }
      }
    }
  }

  ul.links{
    li{
      padding: 5px 0;

      a{
        color: #fff;
      }
    }
  }
}

.footer-bottom{
  background-color: #262727;

  address{
    float: left;
    font-family: 'titillium_weblight';
    font-size: 18px;
    color: #fff;
  }
}

.social-media-footer-links{
  width: 25%;
  float: left;
  text-align: right;

  .social-icons{
    li{
      a{
        width: 50px;
        height: 50px;
        border-radius: 0;

        em{
          &:before{
            line-height: 1.5em;
            font-size: 35px;
          }
        }
      }
    }
  }
}