@media all and (max-width:767px){

  .rotom-form-popup{
    .modal-inner-wrap{
      width: 80%;
      min-width: inherit;
    }

    .customer-name{
      display: block !important;
    }
  }

}