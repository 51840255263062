@media all and (max-width:767px){

  .page-header{
    .switcher{
      .options{
        ul.dropdown{
          left: -119px;

          &:before{
            right: 17px;
            left: auto;
          }

          &:after{
            right: 16px;
            left: auto;
          }
        }
      }
    }
  }

  .page-header.type1{
    .block-search{
      width: inherit;
      margin-left: inherit !important;
    }

    .navigation{
      .level0{
        >.level-top{
          padding: 13px 36px 13px 15px !important;
        }
      }

      li.level0{
        &:hover{
          background-color: #00305A !important;
        }
      }

      li.level0.orange-element{
        margin-left: 0px;

        .level-top{
          padding: 3px 36px 13px 15px !important;
        }
      }

      .submenu{
        -webkit-box-shadow: none !important;
        box-shadow: none !important;

        &:before{
          display: none !important;
        }
      }
    }

    .navigation>ul{
      left: inherit;
    }

    .sw-megamenu.navigation li > a > span{
      color: #fff !important;
    }

    .nav-sections{
      .header.links{
        &:nth-of-type(2){
          display: none;
        }
      }
    }

    .switcher{
      display: block;
      height: 0px;

      .options{
        position: absolute;
        left: 150px;
        top: 23px;
        z-index: 999999;
      }
    }

    .choose-language-text{
      display: none;
    }

    .header.panel > .header.links{
      display: none;
    }

    .switcher-language-span{
      display: none;
    }

  }

  header.page-header{
      .nav-sections-item-title {
        width: 50%;
        &:nth-of-type(3){
          background: #96989A;
        }
      }

    .nav-sections-item-title.active{
      background: #00305A !important;
    }
    }
}

@media all and (max-width:767px) and (orientation: landscape){

  .page-header.type1{
    .switcher{
      .options{
        left: 220px;
      }
    }
  }

}