/*** Homepage Feeds ***/
.mp-blog-rss {
  display: none !important;
}
//reset animations
.column.main {
  transition: none !important;
}

.date-box {
  width: 38px;
  height: 50px;
  line-height: 32px;
  padding: 3px;
  position: absolute;
  background: #00305A;
  top: 0;
  right: 0;
  color: #fff;
  text-align: center;
  z-index: 4;
  .date-year {
    font-size: 20px;
    font-weight: 700;
  }
  .date-month {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }
  div {
    font-weight: 500;
    line-height: 22px;
  }
}

.mp-read-more {
  font-weight: 600;
  &:hover {
    text-decoration: underline !important;
  }
}



/*** Category ***/

.mpblog-category-view {
  .post-info-wraper {
    padding: 25px 15px 10px 25px !important;
  }
  .post-item-wraper {
    padding: 0 !important;
    &:hover{
      box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .sidebar.sidebar-main {
    margin-top: 0;
  }
}

.amount-items-category-page {
  display: none;
}

/*** Posts ***/

.mpblog-post-view {
  .post-view-image {
    text-align: left !important;
    margin-bottom: 30px;
  }
  .post-post_content {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #4e4e4e;
  }
  .mp-post-info {
    float: none;
    margin-bottom: 30px;
  }
  .post-list-content {
    padding: 0 !important;
  }
  .page-title {
    margin-top: 40px;
    margin-bottom: 0 !important;
  }
}

/*** News ***/
.blog {
  .page-main {
    padding-top: 0;
  }
}

.sidebarblog,.blog {
  h1 {
    &.page-title span {
      font-family: 'titillium_websemibold';
      font-size: 28px;
      color: #6699ff !important;
      margin: 30px 0px 30px 0px;
    }
  }
  h2.mp-post-title {
    font-weight: 700;
    margin-top: 25px !important;
    a {
      color: #0088CC !important;
      font-weight: 700;
    }
  }
  .list-post-tabs .post-right span {
    font-weight: 600;
  }
  .post-item-wraper {
    background: #f9f9f9;
    /*min-height: 395px;*/
    border: 0;
    padding: 10px 0px 10px 0px !important;

    &:hover {
      border: 0;
      box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }
  @media (max-width: 991px) {
    .post-info-wraper .mp-post-meta-info {
      bottom: 13px !important;
    }
  }
  .mp-sidebar.mpcss:first-child {
    display: block;
  }
  .date-box {
    display: none;
  }
  .list-post-tabs a, a.mp-read-more {
    color: #6699ff !important;
  }
  .post-info-wraper h2.mp-post-title a, .about-admin h4.admin-title a, .mp-post-meta-info a.mp-read-more, .mp-post-info a.mp-info, ul.menu-categories a.list-categories:hover, .products-same-post a.product-item-link:hover, a.mp-relate-link, .mpblog-product-name {
    color: #6699ff !important;
  }
  .post-info-wraper h2.mp-post-title {
    font-size: 22px;
  }
  .mp-read-more {
    font-size: 13px !important;
    text-decoration: underline !important;
  }
  .on-news {
    display: none;
  }
  .on-blog {
    position: static;
    margin-bottom: 20px;
    margin-top: 5px;
    font-size: 13px !important;
    a.mp-info {
      color: #777 !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
    .posted-in-info {
      display: none;
    }
  }
  .post-list-item {
    margin-bottom: 40px;
  }
  .mp-sidebar.mpcss {
    display: block;
    .row {
      display: block;
      margin-left: 0;
    }
    .block-content {
      padding-left: 0;
      margin-top: 0 !important;
    }
    .category-item {
      margin-bottom: 0;
      line-height: 36px;
      &:before {
        content: "▶";
        font-size: 10px;
        position: relative;
        top: -2px;
        margin-right: 10px;
        text-decoration: none;
      }
      a {
        &:hover {
          text-decoration: underline;
          color: #4e4e4e !important;
        }
      }
    }
    .list-categories {
      margin-left: 0;
    }
    i {
      display: none;
    }
    .block-title p {
      color: #00305A;
      text-transform: uppercase;
      font-weight: 600;
    }
    li {
      border-bottom: 1px solid #e2e0e0;
    }
    a {
      color: #4e4e4e;
      font-size: 16px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .post-image{
    text-align: center;
    width: 100%;
    display: grid;

    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.news {
  .page-main {
    padding-top: 0;
  }
}
.sidebarnews,.news {
  .post-link-title {
    color: #00305A;
  }
  .list-post-tabs a {
    color: #00305A;
  }
  .post-info a.mp-info {
    color: #00305A;
  }
  .post-info-wraper h2.mp-post-title a, .about-admin h4.admin-title a, .mp-post-meta-info a.mp-read-more, .mp-post-info a.mp-info, ul.menu-categories a.list-categories:hover, .products-same-post a.product-item-link:hover, a.mp-relate-link, .mpblog-product-name {
    color: #00305A !important;
  }
  .post-info-wraper h2.mp-post-title a {
    font-size: 22px;
  }
  .mp-sidebar .data.item.title.active a {
    border-bottom: 2px solid #00305A !important;
  }
  .on-blog {
    display: none;
  }
}

.toolbar-products{
  margin: 20px 0px 0px 0px;

  .pages{
    float: right;
    display: block;

    .page{
      border-radius: 0px;
      border: 1px solid #00305A;

      &:hover{
        background: #00305A;
        color: #fff;
      }
    }

    strong.page{
      background: #00305A;
    }

    a{
      color: #00305A;

      &:before{
        color: #00305A;
      }
    }

    .action{
      border: 1px solid #00305A;
      border-radius: 0px;

      &:hover{
        background: #00305A;

        &:before{
          color: #fff;
        }
      }

      &:before{
        color: #00305A;
      }
    }

    >.label{
      position: initial;
      height: inherit;
      width: inherit;
      margin: 0px 10px 0px 5px;
      font-family: 'titillium_webextralight';
      color: #C8C8C8;
      font-size: 15px;
    }
  }

  .limiter{
    margin-right: 10px;
    margin-left: 30px;

    .label{
      font-family: 'titillium_webregular';
      color: #8D8D8D;
      font-size: 15px;
    }
  }

  select{
    border-radius: 0px;
    border: 1px solid #B6B6B6;
    color: #7F7F7F;
  }
}

body .post-list-content .limiter {
  display: none !important;
}

/*** POSTS ***/

/* News */

body.news {
  .mp-post-info {
    position: static;
  }
}

/* Blog */

.sidebar{
  .mpblog-search{
    input{
      border: 1px solid #cecece !important;
    }
  }
}

body.blog {
  .mp-post-info {
    position: static;
    .mp-info {
      color: inherit !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

.mpblog-post-view {
  .block-blog-related {
    .product-item-photo {
      border: 0;
    }
    h2 {
      color: #00305A;
      font-weight: 600;
    }
    .product-item {
      &:hover {
        -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
        .actions {
          a {
            width: 120px;
            background-color: #3f9bf7;
            margin: auto auto;
            padding: 5px 10px 5px 10px;
            border: 1px solid #3f9bf7;
            COLOR: #FFF;
          }
        }
      }
    }
  }
}


.list-post-tabs .post-right span {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 2 !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  white-space: inherit !important;
}

.post-list-content .pages .items .item .page {
  border: 0 !important;
}

.post-list-content .pages .items .item.current .page,
.pages strong.page {
  background: #00305A !important;
  border: 0 !important;
}

.pages .action {
  border-radius: 0 !important;
  border: 0 !important;
  &:hover {
    background: #00305A !important;
  }
}

.pages a.page,
.pages a.page:visited {
  color: #00305A !important;
}

.pages a.page:hover {
  background: #00305A !important;
  color: #fff !important;
}

.related-content-container {
  .products-grid {
    .product-item {
      min-height: 250px;

      .actions-primary {
        width: 120px;
        background-color: #fff;
        margin: auto auto;
        padding: 5px 10px 5px 10px;
        border: 1px solid #3F9BF7;

        a {
          color: #3F9BF7;
        }
        &:hover {
          transition: all 0.2s ease-in-out;
          background: #003059;
          a {
            text-decoration: none;
          }
        }
      }

      &:hover {
        -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);

        .actions-primary {
          width: 120px;
          background-color: #3f9bf7;
          margin: auto auto;
          padding: 5px 10px 5px 10px;
          border: 1px solid #3f9bf7;

          a {
            color: #fff;
          }
        }
      }

      .product-item-details {
        position: relative !important;
        min-height: 140px;

        .product-sku {
          margin: 0px 0px 20px 0px;
        }

        .product-item-name {
          a {
            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .product-item-inner {
        margin: 9px 0px 0 0px;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 0;
      }

      .product-item-info {
        .main-product-item-link {
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
        }
      }

    }
  }

  .product-item-photo {
    border: none;

    .product-label {
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;

      .label-position2, .label-position3 {
        top: 8px;
      }
    }
  }
}

//changes for blog news
.post-list-content.contentNews {
  .post-item-list {
    .post-item-wraper {
      display: flex;
      align-items: stretch;
      height: 250px;
      box-sizing: content-box;
    }
    .post-image{
      width: 35%;
      max-width: 250px;
      height: 250px;
      flex: 0 0 auto;
      display: block;

      a {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          align-items: flex-start;
        }
      }

      img.img-responsive{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        flex-shrink: 0;
      }
    }
    .post-info-wraper {
      display: block;
      flex: 1 1 auto;

      .mp-post-info {
        @media all and (max-width:1220px) {
          position: absolute;
          bottom: 25px;
          right: auto;
          padding-right: 15px;
          text-align: left;
        }
      }
    }
  }
}

//changes for blog
.post-list-content.contentBlog {
  .post-item-list {
    .post-item-wraper {
      display: flex;
      align-items: stretch;
      padding: 0 !important;
      height: 400px;
      box-sizing: content-box;
    }

    .post-image{
      width: 38%;
      max-width: 290px;
      height: 400px;
      flex: 0 0 auto;
      display: block;

      a {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          align-items: flex-start;
        }
      }

      img.img-responsive{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        top: auto;
        transform: none;
        flex-shrink: 0;
      }
    }
    .post-info-wraper {
      display: block;
      flex: 1 1 auto;
    }
  }
}

.headerBlog,
.headerNews {
  h1 {
    margin-top: 0.68em;
  }
}

.page-header.type1 .logo{
    max-width: 200px;
}

