.contact-index-index {

  #store_map{
    min-height: 600px !important;
  }

  .contact-locations-title {
    border-bottom: 2px solid #ededee;
    height: 36px;
    margin-bottom: 20px;

    span {
      border-bottom: 2px solid #6699FF;
      padding-bottom: 6px;
      font-family: 'titillium_webregular';
      color: #00305A;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 20px;
      padding-right: 20px;
      display: inline-block;
      white-space: nowrap;
    }
  }

  .contact-more-information-box {
    margin: 0px 0px 40px 0px;

    .left-side-content {
      width: 50%;
      float: left;

      .information-sub-box {
        float: left;
        width: 60%;

        &:first-of-type {
          width: 40%;
        }

        p {
          font-family: 'titillium_webregular';
          margin: 0px 0px 0px 0px;
          color: #777777;
          font-size: 15px;

          &:first-of-type {
            font-family: 'titillium_webbold';
            color: #777777;
            margin: 0px 0px 5px 0px;
          }
        }
      }
    }

    .right-side-content {
      width: 50%;
      float: left;
    }
  }

  .contact-locations-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -53px;
    margin-right: -53px;
    margin-top: 40px;

    .location-info-box {
      margin: 10px 0px 10px 0px;
      width: 25%;
      padding: 0 65px;

      p {
        font-family: 'titillium_webregular';
        margin: 0px 0px 0px 0px;
        color: #777777;
        font-size: 14px;
        line-height: 15px;

        &:first-of-type {
          font-family: 'titillium_webbold';
          color: #777777;
          margin: 0px 0px 2px 0px;
        }
      }

      .show-onmap-button {
        margin: 6px 0px 0px 0px;
        border: 1px solid #6699FF;
        width: 100%;
        height: 22px;
        text-align: center;
        line-height: 19px;
        cursor: pointer;
        font-family: 'titillium_websemibold';
        font-size: 12px;

        &:hover{
          text-decoration: underline;
        }
      }
    }
  }

  #content-google-map, .gm-style-iw {
    border-radius: 0px;
    text-align: center;
    width: 240px !important; // must add !important because it's overwrite weird javascript calc
    overflow: hidden !important;
    height: unset !important;
    max-height: unset !important;
    height: auto;
    left: 0 !important;
    box-shadow: none;
    padding: 5px 0px 0px 0px;

    #bodyContent {
      margin-top: -3px;
    }

    .more-text#content-google-map {

      p {
        line-height: 14px !important;
      }
      #bodyContent {


      }
    }

    p {
      font-family: 'titillium_webregular';
      margin: 0px 0px 0px 0px;
      line-height: 16px;
    }

    .google-modal-button {
      width: 170px;
      background-color: #6699FF;
      color: white;
      padding: 14px 0px;
      border: none;
      border-radius: 0px;
      cursor: pointer;
      margin: 3px auto 8px auto;
      display: block;
      height: 25px;
      line-height: 0px;
      font-family: 'titillium_webregular';
      font-size: 15px;

      &:hover{
        transition: all 0.2s ease-in-out;
        background: #003059;
      }
    }
  }

  .gm-style .gm-style-iw {
    text-align: center;
    box-shadow: none;
  }
}

.gm-style .gm-style-iw-t::after {
  z-index: -1;
}

.gm-style-iw.gm-style-iw-c {
  .gm-style-iw-d {
  overflow: hidden !important;
  }
}