.magestore-bannerslider-standard{
  width: 100% !important;

  .flex-direction-nav{
    display: none;
  }

  .flex-control-nav{
    bottom: 15px;

    li{
      margin: 0px 0px;

      a{
        width: 20px;
        height: 20px;
        border-radius: 0px;
        margin: 3px 3px;
        background-color: #D8D9D9;
        box-shadow: none;
      }

      .flex-active{
        width: 20px;
        height: 20px;
        border-radius: 0px;
        margin: 3px 3px;
        background-color: #888888;
        box-shadow: none;
      }
    }
  }
}

/*
#banner-slider-demo-1{
  display: none;
}*/
