.products-grid {
  margin: 0 auto !important;
  padding: 0 3%;
  width: 100%;
  position: relative;
  @media(max-width: 992px) {
    padding: 0 4%;
  }
  @media(max-width: 767px) {
    padding: 0 6%;
  }
  @media(max-width: 420px) {
    padding: 0 7%;
  }
}

.category-cms,
.mp-blog-view,
.cms-page-view,
.category-description {
  .widget-product-list-container,
  .widget-product-grid {
    .owl-stage-outer {
      margin: auto;
      .owl-item {
        .product-item {
          width: 95% !important;
          margin: 5px !important;
          padding: 10px 2px 10px 2px !important;
          @media(max-width: 767px) {
            width: 98% !important;
          }
          .product-item-photo {
            padding: 0;
              height: 181px;
              max-height: 181px;
              display: flex;
            .product-image-photo {
              width: auto;
              max-width: 100%;
            }
          }
        }
      }
    }

    .owl-controls {
      position: absolute;
      top: 130px;
      width: 108%;
      left: -4%;

      .owl-nav {
        [class*=owl-] {
          font-size: 40px;
          color: #B0B2B2 !important;
          background: none;
        }

        .owl-prev {
          left: -53px !important;
          position: absolute;
          @media(max-width: 1199px) {
            left: -50px !important;
          }
          @media(max-width: 767px) {
            left: -55px !important;
          }
          @media(max-width: 420px) {
            left: -52px !important;
          }
        }

        .owl-next {
          right: -53px !important;
          position: absolute;
          @media(max-width: 1199px) {
            right: -50px !important;
          }
          @media(max-width: 767px) {
            right: -55px !important;
          }
          @media(max-width: 420px) {
            right: -52px !important;
          }
        }
      }
    }

    .products-grid {
      .product-item {
        min-height: 250px;

        .actions-primary {
          width: 120px;
          background-color: #fff;
          margin: auto auto;
          padding: 5px 10px 5px 10px;
          border: 1px solid #6699FF;

          a {
            color: #6699FF;
          }
          &:hover {
            transition: all 0.2s ease-in-out;
            background: #003059;
            a {
              text-decoration: none;
            }
          }
        }

        &:hover {
          -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);

          .actions-primary {
            width: 120px;
            background-color: #6699FF;
            margin: auto auto;
            padding: 5px 10px 5px 10px;
            border: 1px solid #6699FF;

            a {
              color: #fff;
            }
          }
        }

        .product-item-details {
          position: relative !important;
          min-height: 140px;

          .product-sku {
            margin: 0 0 20px 0;
            font-size: 14px;
            color: #777;
          }

          .product-item-name {
            a {
              &:hover {
                text-decoration: none;
              }
            }
          }
        }

        .product-item-inner {
          margin: 9px 0 0 0;
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 0;
          .actions-primary {
            font-size: 13px;
          }
        }

        .product-item-info {
          .main-product-item-link {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
          }
        }

      }
      .product-item-photo {
        border: none;

        .product-label {
          padding: 0;
          margin: 0;

          .label-position2, .label-position3 {
            top: 8px;
          }
        }
      }
    }
  }
}
