@media all and (max-width:767px){
  .content-container{
    max-width: 540px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .page-layout-2columns-right .product-info-main{
    width: 100% !important;
    padding-left: inherit;
  }

  .page-layout-2columns-right .product.media{
    width: 100% !important;
  }

  .catalog-product-view{

    .sidebar-additional{
      .gallery-placeholder{
        position: initial;
        .fotorama-item{
          display: none;
        }
      }
    }

    .product-info-main{
      min-height: inherit;
      .product.overview{
        min-height: inherit;
      }
      .product-page-forms-buttons {
        position: inherit;
        width: auto;
      }
    }

    .product.data.items{
      .item.content{
        border-top: none;
      }
    }

    .fotorama{
      .fotorama__thumb__arr.fotorama__thumb__arr--left{
        display: none !important;
      }

      .fotorama__thumb__arr.fotorama__thumb__arr--right{
        display: none !important;
      }

      .fotorama__arr--prev{
        left: 0;
        display: block !important;
      }

      .fotorama__arr--next{
        right: 0;
        display: block !important;
      }
    }
    .fotorama__nav-wrap.fotorama__nav-wrap--horizontal{
      display: none !important;
    }

    #detailed-form-button{
      font-size: 13px;
      line-height: 13px;
    }

    #general-form-button{
      font-size: 13px;
      line-height: 13px;
      margin-right: 10px;
    }

    .product.info.detailed{
      #tab-label-additional-title,#tab-label-advox_customerforms_detailedform_index-title{
        padding: 1px 25px 1px 0px;
      }

      #advox-general-form,#advox-detailed-form{

        .item-info-form-box{
          padding: 12px 20px 20px 20px;

          .left-side-form-info-content{
            width: 100%;
            margin: 0px 0px 20px 0px;

            .product-image{
              float: none;
              margin: auto auto;
              width: 80px;
              height: 80px;
            }

            .product-description{
              width: 100%;
            }
          }

          .right-side-form-info-content{
            width: 100%;
            padding: 0px 0px 0px 13px;
            float: none;
            text-align: right;

            .form-info-description{
              width: 170px;

              p{
                &:nth-of-type(1){
                  font-size: 9px;
                }

                &:nth-of-type(2){
                  font-size: 9px;
                }
              }
            }
          }
        }

        .left-side-content{
          width: 100%;
          padding: 0px 0px 0px 0px;
          position: relative;
        }

        .right-side-content{
          width: 100%;
          padding: 0px 0px 0px 0px;

          .input-container{
            display: block;
          }

          .add-file-2ndspan{
            margin: 5px 0px 0px 0px;
            width: calc(100% - 25px);
          }

          .max-file-size-span{
            padding: 0px 0px 0px 0px;
          }

          .document-info-icon{
            margin: 5px 0px 0px 0px;
            right: 0px;
          }
        }

        .inputfile + label{
          overflow: visible;
          max-width: 100%;
          width: 100%;

          .add-file-span{
            width: 100%;
          }
        }

        input[type="submit"]{
          margin: 100px 20px 20px 0px;
        }

        .checkbox-main-container{
          padding: 0px 0px 80px 0px;
          width: 100%;
        }

        &#advox-detailed-form{
          input[type="submit"]{
            margin: 100px 20px 20px 0px;
          }
          
        }

        .minus-span-form-input{
          width: 15px;
          border: 1px solid #EBEBEB;
          background-color: white;
          cursor: pointer;
          text-align: center;
          line-height: 29px;
          font-size: 18px;
          display: inline-block;
          height: 34px;
          padding: 0px 2px 4px 2px;
        }

        .plus-span-form-input{
          width: 15px;
          border: 1px solid #EBEBEB;
          background-color: white;
          cursor: pointer;
          text-align: center;
          line-height: 29px;
          font-size: 18px;
          display: inline-block;
          height: 34px;
          padding: 0px 2px 4px 2px;
        }

        #counter {
          height: 34px;
        }
      }
    }

    .block.upsell{
      margin: 20px 0px 50px 0px;
      padding: 20px 0px 0px 0px;

      .owl-nav{
        .owl-prev{
          left: -33px;
          position: absolute;
        }

        .owl-next{
          right: -33px;
          position: absolute;
        }
      }
    }

    .info-icon-right-side-popup{
      background-image: url("../images/icons/info.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      display: inline-block;
      width: 30px;
      height: 30px;
      top: 5px;
      position: relative;
    }

    .gallery-placeholder{
      .fotorama__nav-wrap.fotorama__nav-wrap--horizontal{
        display: none !important;
      }
      .fotorama__stage{
        border: none;
        height: 290px !important;
      }
      .product-video{
        height: 100%;
        top: 0;
      }
    }

    a#tab-label-advox_customerforms_generalform_index-title{
      padding: 0px 25px 0px 0px !important;
    }
  }

  .service-block-social-media-block{
    display: none;
  }

  .service-block-fixed-position{
    display: none;
  }
}
