@media all and (min-width:767px) and (max-width:1220px){

  .page-header.type1{
    .block-search{
      margin-left: 45px !important;
      width: 500px;
    }

    .custom-block{
      right: 0px !important;
    }

    .navigation{

      li.level0{
        a.level-top {
          padding: 0 11px;
          span {
            font-size: 14px;
          }
        }
        .submenu{
          width: 70% !important;
        }
      }
    }
  }

}