.cms-general-form{

  #advox-general-form,#advox-detailed-form{
    width: 100%;
    background-color: #F9F9F9;
    position: relative;

    .general-success-input-border {
      border: 1px solid green;
    }
    .general-error-input-border {
      border: 1px solid #e02b27;
    }

    .file-extension-error-message,.file-size-error-message{
      font-size: 12px;
      color: #e02b27;
      font-family: 'titillium_webregular';
      padding: 0px 0px 0px 15px;
      position: relative;
      bottom: 5px;
    }

    .email-reg-exp-error,.phone-reg-exp-error{
      font-size: 12px;
      color: #e02b27;
      font-family: 'titillium_webregular';
      margin: 5px 0px 0px 0px;
    }

    .checkboxes-required-element-error,.checkboxes-required-element{
      margin: 0px 0px 0px 15px;
    }

    .checkbox-main-container{
      margin: 10px 0px 0px 0px;
      padding: 0px 0px 20px 0px;
      width: 35%;
    }

    .left-side-content{
      width: 35%;
      float: left;
    }

    .right-side-content{
      width: 65%;
      float: left;
      padding: 0px 0px 0px 10px;
    }

    .input-container{
      display: block;
      width: 100%;
      margin-bottom: 8px;
      padding: 0px 20px 0px 15px;
      position: relative;

      div.mage-error[generated]{
        display: none !important;
      }

      input.mage-error{
        border: 1px solid #EF4B57;
      }
    }

    .checkbox-general-form-error-message+.checkmark{
      border: 1px solid red;
    }

    .input-icon-box{
      color: white;
      min-width: 44px;
      text-align: center;
      float: left;
      width: 40px;
      height: 35px;
    }

    .document-info-icon{
      background-image: url("../images/icons/document_icon.svg");
      display: inline-block;
      width: 15px;
      background-size: 80%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 36px;
      line-height: 36px;
      vertical-align: top;
      position: relative;
      right: -11px;
    }

    input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"], textarea{
      border-radius: 0px;
      border: none;
      font-family: 'titillium_webregular';
    }

    input[type="text"],input[type="email"],input[type="tel"]{
      height: 35px;
      border: 1px solid #EBEBEB;
      width: calc(100% - 45px);
      font-family: 'titillium_webregular';
    }

    input[type="submit"]{
      width: 180px;
      background-color: #6699FF;
      color: white;
      padding: 14px 20px;
      border: none;
      border-radius: 0px;
      cursor: pointer;
      margin: 57px 20px 15px auto;
      display: block;
      height: 40px;
      line-height: 2px;
      font-family: 'titillium_webregular';
      font-size: 15px;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0px 20px 20px 0px;
      z-index: 4;
    }

    ._keyfocus *:focus, input:not([disabled]):focus, textarea:not([disabled]):focus, select:not([disabled]):focus{
      box-shadow: none;
    }

    ::placeholder {
      color: #A4A4A4;
      opacity: 1;
      font-family: 'titillium_webregular';
    }

    textarea{
      width: 100%;
      display: block;
      height: 113px;
      border: 1px solid #EBEBEB;
    }

    textarea.mage-error{
      border: 1px solid #EF4B57;
    }

    /*CHECKBOXES START*/

    .label-container {
      display: block;
      position: relative;
      padding-left: 26px;
      margin-bottom: 0px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .label-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 0px;
      left: 17px;
      height: 15px;
      width: 15px;
      background-color: #fff;
      border: 1px solid #B0B2B2;
    }

    .label-container:hover input ~ .checkmark {
      background-color: #fff;
    }

    .label-container input:checked ~ .checkmark {
      background-color: #fff;
      border: 1px solid #B0B2B2;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .label-container input:checked ~ .checkmark:after {
      display: block;
    }

    .label-container .checkmark:after {
      left: 5px;
      top: -1px;
      width: 5px;
      height: 14px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .label-container{
      p{
        font-family: 'titillium_webregular';
        color: #A2A4A4;
        font-size: 12px;
        padding: 0px 15px 0px 12px;
        margin-bottom: 0;
      }
    }

    .inputfile{
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .inputfile + label{
      border: 1px solid #6699FF;
      background-color: #fff;
      padding: 0;
      color: #6699FF;
      max-width: 80%;
      font-size: 1.25rem;
      font-weight: 700;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      margin-left: 2px;
      width: 95px;
      height: 35px;
      vertical-align: top;
    }

    .inputfile + label .contact-form-attachment-icon{
      width: 37px;
      height: 33px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: top;
    }

    .inputfile + label .add-file-span{
      width: 50px;
      font-family: 'titillium_webextralight';
      margin: 0px 0px 0px 7px;
      line-height: 32px;
      font-size: 12px;
    }

    .add-file-2ndspan{
      display: inline-block;
      height: 35px;
      margin: 0px 0px 0px 15px;
      overflow: hidden;
      white-space: nowrap;
      line-height: 33px;
      padding: 0px 0px 0px 10px;
      font-size: 14px;
      color: #6699FF;
      vertical-align: top;
    }

    .add-file-3rdspan{
      display: inline-block;
      height: 35px;
      overflow: hidden;
      white-space: nowrap;
      line-height: 33px;
      font-size: 14px;
      color: #9F9F9F;
      vertical-align: top;
    }

    .delete-attachment{
      display: none;
      height: 35px;
      margin: 0px 0px 0px 0px;
      overflow: hidden;
      white-space: nowrap;
      line-height: 33px;
      padding: 0px 0px 0px 5px;
      font-size: 14px;
      color: red;
      cursor: pointer;
    }

    .max-file-size-span{
      font-size: 13px;
      line-height: 27px;
      padding: 0px 0px 0px 15px;
      color: #9F9F9F;
      bottom: 0px;
      position: relative;
      display: inline-block;
      height: 35px;
      line-height: 33px;
      overflow: hidden;
      vertical-align: top;
    }

    .checkbox-general-form-error-message{
      margin-top: 0px !important;
      position: relative !important;
      display: block !important;
      top: 1px !important;
      left: 12px !important;
      width: 200px !important;
      font-size: 10px !important;
      text-align: left !important;
      color: #e02b27 !important;
      &:before {
        content:" *";
      }
    }

    // CHECKBOXES END

    .minus-span-form-input{
      width: 18px;
      border: 1px solid #EBEBEB;
      background-color: white;
      cursor: pointer;
      text-align: center;
      line-height: 23px;
      font-size: 18px;
      height: 28px;
    }

    .plus-span-form-input{
      width: 18px;
      border: 1px solid #EBEBEB;
      background-color: white;
      cursor: pointer;
      text-align: center;
      line-height: 23px;
      font-size: 18px;
      height: 28px;
    }

    #counter{
      width: 45px;
      text-align: center;
      border: 1px solid #EBEBEB;
      height: 28px;
      color: #27466B;
    }

    .quantity-span{
      font-family: 'titillium_webregular';
      line-height: 27px;
      margin: 0px 0px 0px 10px;
    }
  }

  .item-info-form-box{
    padding: 12px 20px 20px 20px;

    .left-side-form-info-content{
      float: left;
      width: 0%;

      .product-image{
        width: 50px;
        height: 50px;
        float: left;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .product-description{
        float: left;
        padding: 0px 12px 0px 12px;
        p{
          &:nth-of-type(1){
            font-family: 'titillium_webregular';
            color: #00305A;
            font-weight: 600;
            font-size: 16px;
            margin: 0px 0px 0px 0px;
          }

          &:nth-of-type(2){
            font-family: 'titillium_webregular';
            color: #9EA0A0;
            font-size: 15px;
            font-weight: 400;
            margin: 2px 0px 0px 0px;
          }
        }
      }
    }

    .right-side-form-info-content{
      float: left;
      width: 100%;
      text-align: right;

      .form-info-icon{
        width: 25px;
        height: 25px;
        display: inline-block;
      }

      .form-info-description{
        display: inline-block;
        padding: 0px 5px 0px 12px;

        p{
          &:nth-of-type(1){
            font-family: 'titillium_webregular';
            color: #00305A;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            margin: 0px 0px 0px 0px;
            text-transform: uppercase;
            text-align: right;
          }

          &:nth-of-type(2){
            font-family: 'titillium_webregular';
            color: #9EA0A0;
            font-size: 12px;
            line-height: 10px;
            font-weight: 500;
            color: #00305A;
            margin: 4px 0px 0px 0px;
            text-transform: uppercase;
            text-align: right;
          }
        }
      }
    }
  }
}