@media all and (min-width:767px) and (max-width:1220px){

  .catalog-top-banner-background-image{
    display: block;
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .catalog-category-view,.catalogsearch-result-index{

    .subcategories-list-static-block-container{
      .category-slide{
        margin: 0px 15px 25px 15px;
        width: 200px;
        .category-content{
          p{
            width: 90%;
          }
        }
      }
    }

    .products-grid{
      .product-item{
        min-height: 250px !important;
        width: 100%;

        .actions-primary{
          display: block !important;
        }
      }

    }

    .product-item{
      min-height: 350px !important;

      .actions-primary{
        display: block !important;
      }
    }

    .form-general-container{
      #advox-general-form{
        textarea{
          width: 80%;
        }

        .inputfile + label{
          width: 99%;
        }

        .add-file-2ndspan{
          max-width: calc(100% - 25px);
        }
      }
    }

    .layered-filter-block-container{
      .filter-options-content{
        .items-box{
          width: calc(100% - 15px);
        }
      }
    }

    .service-block-social-media-block{
      position: relative;
      left: -32px;
      z-index: 4;
      top: 15px;

      .social-icons{
        top: 25px;
      }
    }

    .service-block-fixed-position{
      position: fixed;
      top: 100px;
      margin-left: -80px;
      left: inherit;
    }
  }
}

@media all and (min-width:992px) and (max-width:1220px){
  .catalog-category-view,.catalogsearch-result-index{
    .service-block-social-media-block{
      position: relative;
      left: -24px;
      z-index: 4;
      top: 15px;

      .social-icons{
        top: 25px;
      }
    }
    .service-block-fixed-position{
      position: fixed;
      top: 122px;
      margin-left: -89px;
      left: inherit;
    }
  }
}