@media all and (max-width:767px){

  .catalog-top-banner-background-image{
    display: block;
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .catalog-category-view,.catalogsearch-result-index{

    .subcategories-list-static-block-container{
      padding-left: inherit;
      justify-content: center;

      .category-slide{
        margin: 0px 15px 25px 15px;
        .category-content{
          p{
            width: 90%;
          }
        }
      }
    }

    .products-grid{
      .product-item{
        min-height: 250px !important;
        width: 50%;

        .actions-primary{
          display: block !important;
        }
        .product-item-photo {
          .product-label {
            padding: 12px !important;
          }
        }
      }
    }

    .pages{
      margin: 10px auto 15px auto;
      float: none !important;
    }

    .toolbar-products{
      strong#paging-label{
        display: none !important;
      }
    }

    .toolbar{
      .limiter{
        float: none;
        text-align: center;
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    .form-general-container{
      #advox-general-form{
        textarea{
          width: 90%;
        }
        .inputfile + label{
          width: 99%;
        }

        .add-file-2ndspan{
          max-width: calc(100% - 25px);
          width: 100%;
        }
      }
    }

    .layered-filter-block-container{
      .filter-options-item{
        padding: 0px 10px 0px 10px;
      }

      .filter-options-content{
        .items-box{
          width: calc(100% - 15px);
        }
      }
    }

    .service-block-social-media-block{
      position: absolute;
      left: -30px;
      z-index: 4;
      margin: 110px 0px 0px 0px;
      top: 160px;
    }

    .service-block-fixed-position{
      position: fixed;
      margin: 110px 0px 0px 0px;
      margin-left: -45px;
      left: inherit;
      top: 0px;
    }

    .filter-title{
      strong{
        top: calc(22% + 65px);
      }
    }
  }

  .amount-items-category-page{
    float: none;
  }
}