@media all and (max-width:767px){

  .footer-top{
    .content{
      margin: 20px 0px 0px 0px;
      width: 100%;
    }

    .pre-content{
      width: 100%;
      text-align: center;
    }

    .container{
      border-bottom: none;

      .social-media-footer-links{
        margin: 30px 0px 0px 0px;
        width: 100%;
        text-align: center;
      }
    }

    .block.newsletter{

      width: 100% !important;
      .form.subscribe,.checkboxes-required-element{
        margin: auto auto;
        width: 310px;
      }
    }

    .checkbox-main-container{
      width: 310px !important;
      margin: 20px auto 0px auto;
    }
  }

  .footer-middle{
    .block{
      .block-title{
        margin-bottom: 10px;
        margin-top: 20px;
      }
    }

    .container{
      .row{
        .col-lg-3{
          padding-left: 40px;
        }
      }
    }
  }

}