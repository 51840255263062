.modal-popup._show .modal-inner-wrap{
  position: relative;
  top: 50%;
  transform: translateY(-60%);
}


.rotom-form-popup{
  text-align: center;

  .success-popup-image{
    background-image: url("../images/inquiry-confirmation.gif");
    background-size: 100% 100%;
    display: block;
    width: 120px;
    height: 120px;
    margin: auto auto;
  }

  .thank-you-text{
    font-size: 22px;
    color: #6C6C6C;
    font-family: 'titillium_websemibold';
    padding: 20px 0px 5px 0px;
    line-height: 22px;
  }

  .customer-name{
    font-family: 'titillium_webregular';
    margin: 0px 0px 20px 0px;
    font-size: 14px;
  }

  .request-text{
    font-family: 'titillium_webregular';
    color: #7A7A7A;
    font-size: 14px;
    line-height: 15px;

    span{
      font-family: 'titillium_websemibold';
      color: #6C6C6C;
    }
  }

  .customer-email{
    font-family: 'titillium_websemibold';
    color: #6C6C6C;
    font-size: 14px;
    line-height: 15px;
  }

  .modal-inner-wrap{
    width: 40%;
    min-width: 760px;


  }

  .modal-header{
    padding-bottom: 4rem;
    padding-top: 4rem;
  }

  .modal-footer{
    padding-bottom: 4rem;
    padding-top: 4rem;
    button{
      display: none !important;
    }
  }
}

.modal-custom .action-close:before, .modal-popup .action-close:before, .modal-slide .action-close:before{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  line-height: 20px;
  color: #464646;
  content: '\f819';
  font-family: 'porto-icons';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}