.page-header{
  border-top: 0;
  .switcher{
    .options{
      ul.dropdown{
        right: 5px;
        left: inherit;

        &:before{
          right: 17px;
          left: auto;
        }

        &:after{
          right: 16px;
          left: auto;
        }
      }
    }
  }
}

header.page-header.sticky-header.type1{
  .nav-sections{
    padding: 0px 0px 0px 0px !important;
  }

  .navigation li.level0.parent > a{
    &:after{
      line-height: 40px !important;
    }
  }

  .navigation li.level0 > .level-top{
    line-height: 40px !important;
  }
}


.page-header.type1{

  .custom-block{
    right: 32px !important;
  }

  .nav-sections{
    margin-bottom: 0px !important;
    background-color: #00305A !important;

    .header.links{
      font-family: 'titillium_webregular';

      a{
        font-size: 17px;
        font-family: 'titillium_webextralight';
        color: #fff;
      }
    }
  }

  .navigation>ul{
    background: none !important;
    position: relative;
    left: -6px;
  }

  .megamenu-right-side-container{

    img{
      width: inherit !important;
      margin: auto auto;
      padding: 15px 15px 15px 15px;
      max-height: 300px;
      display: block;
    }
  }

  .block-search{
    width: 550px;
    margin-left: 90px !important;

    input{
      border-radius: 0px;
      box-shadow: none;
      font-family: 'titillium_webregular';
      color: #000;
    }

    .action.search{
      background: #00305A;
      right: 0px !important;
      padding: 0 23px !important;

      &:before,&:hover{
        color: #fff !important;
      }
    }

    .action.search[disabled]{
      opacity: 1;
    }
  }

  .custom-block{
    line-height: initial !important;
    text-align: right;
    height: 50px;
    margin-top: -23px !important;
      .infobox{
        width: 100%;
        line-height: 18px;

        a{
          text-decoration: none;
          color: #777;
          font-size: 17px;
        }
      }
  }

  .sw-megamenu.navigation{
    li>a>span{
      font-size: 17px;
      font-family: 'titillium_webextralight';
      color: #fff;
    }
  }

    .navigation{
      padding: 0px 0px !important;

      .level0>.level-top{
        text-transform: inherit;
        padding: 0px 30px;
      }

      li.level0{

        &:hover{
          background-color: #6699FF !important;
          border-radius: 0px !important;
        }

        >a{
          &:after{
            color: #fff;
          }
        }

        .submenu{
          border: none !important;
          left: inherit !important;
          width: calc(80% - 100px) !important;
          border-radius: 0 0 0px 0px !important;
          -webkit-box-shadow: 0px 0px 34px -4px rgba(0,0,0,0.75);
          -moz-box-shadow: 0px 0px 34px -4px rgba(0,0,0,0.75);
          box-shadow: 0px 0px 34px -4px rgba(0,0,0,0.75);

          &:before{
            content: '';
            width: 0px;
            height: 0px;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-bottom: 10px solid #fff;
            position: absolute;
            top: -8px;
            left: 35px;
          }

          li.level1{
            >a{
              text-transform: none !important;
            }
          }
        }
      }

      li.level0.orange-element{
        border-radius: 0px;
        background-color: #FF9F3E;
        margin-left: -6px;
        margin-right: -3px;

        &:hover{
          background-color: #EB8126 !important;
        }

      }

      .circle-cart-icon{
        display: inline-block;
        width: 26px;
        height: 26px;
        background: white;
        -moz-border-radius: 25px;
        -webkit-border-radius: 25px;
        border-radius: 25px;
        background-image: url(../images/icons/menuIconCart.svg);
        background-size: 60% 60%;
        background-repeat: no-repeat;
        background-position: 60% 50%;
        position: relative;
        top: 7px;
        left: -8px;
      }
    }

  .subchildmenu{
    .ui-menu-item{
      span{
        color: #00305A !important;
        text-decoration: none !important;

        &:hover{
          color: #6699FF !important;
        }
      }
    }
  }

  .header.panel>.header.links{
    float: left;

    >li{
      >a{
        border: none;
        text-decoration: none;
        color: #777 !important;
        font-size: 14px;

        &:hover{
          text-decoration: underline;
        }
      }
    }
  }

  a.level-top.ui-state-active{
    background-color: #00305A !important;
  }

  .navigation li.level0>a.ui-state-active+.submenu{
    background-color: #00305A !important;
  }

  .choose-language-text{
    display: inline-block;
    font-size: 14px;
    font-family: 'titillium_webregular';
  }

  .switcher-language-span{
    font-size: 14px;
    line-height: 14px;
    font-family: 'titillium_webregular';
    margin: 0px 0px 0px 5px;
  }

  .switcher{
    float: right;
    border-left: none !important;
    line-height: 15px;
  }

  .website-strong-name{
    color: black;
    padding: 0px 0px 0px 10px;
  }

}