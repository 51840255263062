@media all and (max-width:767px){

  .cms-general-form {

    #advox-general-form, #advox-detailed-form {

      .item-info-form-box{
        padding: 12px 0px 20px 0px;

        .left-side-form-info-content{
          width: 100%;
          margin: 0px 0px 0px 0px;

          .product-image{
            float: none;
            margin: auto auto;
            width: 80px;
            height: 80px;
          }
        }

        .right-side-form-info-content{
          width: 100%;
          padding: 0px 0px 0px 13px;
          float: none;
          text-align: left;

          .form-info-description{
            width: 150px;
            padding: 0px 0px 0px 0px;

            p{
              &:nth-of-type(1){
                font-size: 9px;
              }

              &:nth-of-type(2){
                font-size: 9px;
              }
            }
          }
        }
      }

      .left-side-content {
        width: 100%;
        padding: 0px 0px 0px 0px;
        position: relative;
      }

      .right-side-content {
        width: 100%;
        padding: 0px 0px 0px 0px;

        .input-container {
          display: block;
        }

        .add-file-2ndspan{
          margin: 5px 0px 0px 0px;
          width: calc(100% - 25px);
        }

        .max-file-size-span {
          padding: 0px 0px 0px 0px;
        }

        .document-info-icon{
          right: 0px;
        }
      }

      .inputfile + label {
        overflow: visible;
        max-width: 100%;
        width: 100%;

        .add-file-span {
          width: 100%;
        }
      }

      input[type="submit"] {
        margin: 100px 20px 20px 0px;
      }

      .checkbox-main-container {
        padding: 0px 0px 80px 0px;
        width: 100%;
      }

      &#advox-general-form {
        input[type="submit"] {
          margin: 100px 20px 20px 0px;
        }

        .checkbox-main-container {
          padding: 0px 0px 80px 0px;
          width: 100%;
        }
      }

      .minus-span-form-input {
        width: 15px;
        border: 1px solid #EBEBEB;
        background-color: white;
        cursor: pointer;
        text-align: center;
        line-height: 23px;
        font-size: 18px;
        display: inline-block;
        height: 28px;
      }

      .plus-span-form-input {
        width: 15px;
        border: 1px solid #EBEBEB;
        background-color: white;
        cursor: pointer;
        text-align: center;
        line-height: 23px;
        font-size: 18px;
        display: inline-block;
        height: 28px;
      }
    }
  }
}