//Mixins
@import 'blocks/fonts';
@import 'blocks/icons';
@import 'mixins/mixins';


//Blocks
@import 'blocks/rwd/320/icons';
@import 'blocks/rwd/768/icons';
@import 'blocks/forms';
@import 'blocks/typography';


@import 'blocks/popups';
@import 'blocks/rwd/320/popups';
@import 'blocks/rwd/768/popups';

@import 'blocks/bannerHome';
@import 'blocks/rwd/320/bannerHome';
@import 'blocks/rwd/768/bannerHome';

@import 'blocks/widgetSlider';
@import 'blocks/rwd/320/widgetSlider';


//Theme variables
@import 'variables';

//Sections
@import 'sections/navbar';
@import 'sections/rwd/768/navbar';
@import 'sections/rwd/320/navbar';
//-------------------//
@import 'sections/footer';
@import 'sections/rwd/320/footer';
@import 'sections/rwd/768/footer';
//-------------------//
@import 'sections/languageModal';
@import 'sections/rwd/320/languageModal';
@import 'sections/rwd/768/languageModal';
//-------------------//
@import 'sections/pdfAttachment';
//Blocks
@import 'blocks/forms';
@import 'blocks/rwd/320/forms';
@import 'blocks/rwd/768/forms';

//Pages with RWD styles
@import 'pages/mainPage';
@import 'pages/rwd/768/mainPage';
@import 'pages/rwd/320/mainPage';

@import 'pages/serviceBlocksTemplate';
@import 'pages/rwd/768/serviceBlocksTemplate';
@import 'pages/rwd/320/serviceBlocksTemplate';

@import 'pages/mainCategoryPage';
@import 'pages/rwd/768/mainCategoryPage';
@import 'pages/rwd/320/mainCategoryPage';


@import 'pages/productPage';
@import 'pages/rwd/768/productPage';
@import 'pages/rwd/320/productPage';

@import 'pages/contactPage';
@import 'pages/rwd/768/contactPage';
@import 'pages/rwd/320/contactPage';

@import 'pages/blogPages';

//-------------------//

