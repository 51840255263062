@media all and (max-width:767px){
  .column.main {
    > .post-list-body {
      width: 100%;
      margin: 0 0 0 0;

      .post-list-main-container {
        .single-post-box {
          .single-post-content-box {
            width: 70%;
            h2 a {
              max-width: initial;
            }
          }
        }
      }
    }

    .info-banner-container {
      flex-direction: column;
      margin: 40px 0px 40px 0px;

      .info-slide {
        width: 220px;
        margin: 20px auto 20px auto;
      }
    }
  }

  .flex-slider {
    .owl-controls{
      display: none !important;
    }
  }

  .services-slider,.products-slider{
    .owl-controls{
      .owl-nav{
        .owl-prev{
          left: -35px !important;
        }
        .owl-next{
          right: -35px !important;
        }
      }
    }
  }

}