@media all and (min-width:767px) and (max-width:1220px){

  .contact-index-index{

    #store_map{
      min-height: 300px !important;
    }

    .contact-locations-container{
      margin-left: -30px;
      margin-right: -30px;

      .location-info-box{
        width: 30%;
        padding: 0 30px;
      }
    }

    .contact-locations-title{
      span{
        border-bottom: 2px solid #6699FF;
        padding-bottom: 6px;
        font-family: 'titillium_webregular';
        color: #00305A;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        padding-right: 20px;
        display: inline-block;
      }
    }

    .contact-more-information-box{
      .left-side-content{
        width: 100%;
      }

      .right-side-content{

        margin: 20px 0px 20px 0px;
        width: 100%;
      }
    }

    .contact-index-index .gm-style .gm-style-iw {
      max-width: 235px !important;
    }

    .gm-style-iw.gm-style-iw-c {
      height: 140px !important;
      max-height: unset !important;
      max-width: unset !important; // must add !important because it's overwrite weird javascript calc
      .gm-style-iw-d {
        max-height: unset !important;
        max-width: 240px !important;
      }
    }
  }
}