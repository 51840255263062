@media all and (max-width:767px){

  .service-page-icon-facebook{
    width: 20px;
    height: 20px;
  }

  .service-page-icon-tweeter{
    width: 20px;
    height: 20px;
  }

  .service-page-icon-linkedin{
    width: 20px;
    height: 20px;
  }

  .service-page-icon-youtube{
    width: 20px;
    height: 20px;
  }

  .social-icons>li>a{
    width: 20px;
    height: 20px;
    line-height: 28px;
  }

}