@media all and (max-width:767px){

  .service-template-block,.aboutus-template-block{
    .one-third-content-box{
      .smaller-content-box{
        width: 100%;
        float: left;
      }

      .bigger-content-box{
        width: 100%;
        float: left;

        img{
          padding: 0px 0px 0px 0px;
        }
      }
    }

    .info-banner-container{
      flex-direction: column;

      .info-slide{
        margin: auto auto;
      }
    }

    .service-block-social-media-block{
      display: none;
    }

    .service-block-fixed-position{
      display: none;
    }

    .cms-top-banner-background-image{
      display: block;
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      height: 350px;
    }
  }
}