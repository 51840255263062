.cms-top-banner-background-image{
  display: block;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  height: 350px;
}

.service-template-block,.aboutus-template-block,.page-title-wrapper{
  position: relative;

  h1.page-title {
    margin-bottom: 24px;
    span {
      font-family: 'titillium_websemibold';
      font-size: 28px;
      color: #00305A;
      margin: 30px 0px 30px 0px;
    }
  }

  h2{
    font-family: 'titillium_websemibold';
    font-size: 28px;
    color: #00305A;
    margin: 30px 0px 30px 0px;
  }

  h3{
    font-family: 'titillium_websemibold';
    font-size: 18px;
    color: #00305A;
    margin: 30px 0px 5px 0px;
  }

  p{
    font-family: 'titillium_webregular';
    font-size: 17px;
    color: #666666;
  }

  img{
    margin: 30px auto 30px auto;
    display: block;
  }

  ul{
    list-style-type: none;
    padding: 0px 0px 0px 0px;
    margin: 30px 0px 30px 0px;

    li{
      font-family: 'titillium_webregular';
      font-size: 17px;
      margin: 0px 0px 0px 0px;
      &:before {
        content:"-";
      }
    }
  }

  .service-block-social-media-block{
    position: absolute;
    left: -80px;
    top: 15px;
    z-index: 4;
    transform: translate3d(0,0,0);

    .social-icons{
      position: absolute;
      left: -7%;
      width: 40px;
      top: -45px;

      >li{
        >a{
          margin-left: 0px;
          height: 40px;
          width: 40px;
          background: none;
        }
        &:before {
          content: "";
        }
      }
    }
  }

  .service-block-fixed-position{
    position: fixed;
    top: 100px;
    margin-left: -80px;
    left: inherit;
  }

  .one-third-content-box{
    .smaller-content-box{
      width: 30%;
      float: left;
    }

    .bigger-content-box{
      width: 70%;
      float: left;

      img{
        padding: 0px 0px 0px 30px;
      }
    }
  }

  .info-banner-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 20px 0px 20px 0px;

    .info-slide{
      width: 180px;

      .info-image{
        text-align: center;
        img{
          width: 80px;
          margin: auto auto;
          height: 80px;
        }
      }

      .info-content{
        h3{
          color: #00305A;
          font-family: 'titillium_websemibold';
          font-size: 17px;
          text-align: center;
          margin-bottom: 20px;

          span{
            display: block;
            width: 100%;
            font-size: 17px;
          }
        }
      }
    }
  }

  .cms-top-banner-background-image{
    display: block;
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: 50% 50%;
  }
}

.quote-block{
  margin: 40px 0px 40px 0px;

  h1,h3{
    border-bottom: 2px solid #6699FF;
    font-family: 'titillium_webregular';
    font-size: 22px;
    color: #00305A;
    text-transform: uppercase;
    padding: 0px 0px 5px 0px;
    margin-bottom: 5px;
  }

  h4, p, span{
    font-family: 'titillium_webXLtIt';
    color: #666;
    font-size: 17px;
    margin: 10px 0px 10px 0px;
    line-height: 1.3em;

    &:nth-of-type(2){
      font-family: 'titillium_webregular';
      font-size: 17px;
      text-align: left;
    }
  }

  img{
    width: 100%;
  }
}

.additional-block{
  margin: 40px 0px 40px 0px;

  h3{
    border-bottom: 2px solid #6699FF;
    font-family: 'titillium_webregular';
    font-size: 22px;
    color: #00305A;
    text-transform: uppercase;
    padding: 0px 0px 5px 0px;
    margin-bottom: 5px;
  }

  h4{
    padding-left: 30px;
    position: relative;
    font-family: 'titillium_webregular';
    color: #666;
    font-size: 17px;
    line-height: 1.3em;
    font-weight: 400;


    &:before{
      content: "";
      display: inline-block;
      position: absolute;
      background: #6699FF;
      left: 14px;
      top: 4px;
      width: 6px;
      height: 12px;
    }
  }

  a{
    color: #6699FF !important;
  }
}

.our-brands-logotypes-block{
  img{
    max-width: 70%;
    margin: 15px auto 5px auto;
    display: block;
  }
}

.cms-general-form #advox-general-form input[type="submit"]:hover,
.cms-general-form #advox-detailed-form input[type="submit"]:hover {
  transition: all 0.2s ease-in-out;
  background: #003059;
  border: 1px solid #003059;
}

.category-view-blocks{
  .post-list-body{
    width: inherit;
    float: inherit;
  }
}

