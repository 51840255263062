@media all and (max-width:767px){

  .belgiumPopup{
    .modal-inner-wrap{
      max-width: 100%;

      .modal-language-be-languages-switch-box{
        width: 70%;
        margin: 20px auto 40px auto;
      }
    }
  }

  .belgiumPopup.modal-slide{
    width: 100%;
    left: 0px;
  }

}