@media all and (min-width:767px) and (max-width:1220px){
  .content-container{
    max-width: 720px;
    width: 100%;
  }

  .catalog-product-view{
    .product.media{
      .fotorama{
        .fotorama__thumb__arr.fotorama__thumb__arr--left{
          top: 315px;
          left: -30px;
        }

        .fotorama__thumb__arr.fotorama__thumb__arr--right{
          top: 315px;
          right: -27px;
        }

        .fotorama__arr--prev{
          left: 0;
          display: block !important;
        }

        .fotorama__arr--next{
          right: 0;
          display: block !important;
        }
      }
    }

    .sidebar-additional{
      .gallery-placeholder{
        position: initial;

        .fotorama-item{
          display: none;
        }
      }
    }

    .block.upsell{
      margin: 20px 0px 50px 0px;
      padding: 20px 0px 0px 0px;
    }

    #detailed-form-button{
      font-size: 13px;
      line-height: 13px;
    }

    #general-form-button{
      font-size: 13px;
      line-height: 13px;
      margin-right: 10px;
    }

    #advox-general-form{
      .inputfile + label{
        width: 100px;
      }
      .add-file-2ndspan{
        width: 120px;
      }
    }

    .item-info-form-box{
      .left-side-form-info-content{
        float: left;
        width: 100%;
      }

      .right-side-form-info-content{
        float: none;
        width: 100%;
        text-align: right;
        padding-bottom: 20px;

        .form-info-description{
          text-align: left;
          margin: 20px 0px 0px 0px;
        }
      }
    }

    .product-info-main{
      min-height: initial;

      .product.overview{
        min-height: 125px;
      }

      .product-page-forms-buttons{
        position: inherit;
        width: 100%;
      }
    }

    .info-icon-right-side-popup{
      background-image: url("../images/icons/info.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      display: inline-block;
      width: 30px;
      height: 30px;
      top: 5px;
      position: relative;
    }
}

  .service-block-social-media-block{
    position: relative;
    left: -32px;
    z-index: 4;
    top: 15px;

    .social-icons{
      top: 25px;
    }
  }

  .service-block-fixed-position{
    position: fixed;
    top: 165px;
    margin-left: -80px;
    left: inherit;
  }

}

@media all and (min-width:992px) and (max-width:1220px){

  .catalog-product-view{
    .service-block-social-media-block{
      position: relative;
      left: -20px;
      z-index: 4;
      top: 15px;

      .social-icons{
        top: 25px;
      }
    }

    .service-block-fixed-position{
      position: fixed;
      top: 122px;
      margin-left: -85px;
      left: inherit;
    }
  }
}