@media all and (min-width:767px) and (max-width:990px){

  .footer-top{
    .content{
      float: left;
      margin: 20px 0px 0px 0px;
    }

    .container{
      border-bottom: none;

      .social-media-footer-links{
        margin: 72px 0px 0px 0px;
      }
    }

    .block.newsletter{

      .form.subscribe,.checkboxes-required-element{
        margin: auto auto;
        width: 390px;
      }
    }
  }

  .footer-middle{
    .container{
      .col-lg-3{
        width: 50%;
      }
    }
  }

}