.belgiumPopup{
  background: url("../images/rotomBelgiumBackground.jpg");
  background-size: cover;

  .modal-inner-wrap{
    max-width: 320px;
    margin: 5rem auto;

    .action-close{
      display: none;
    }

    .modal-language-be-logo-container{
      margin: auto auto;
      height: 100px;
      background: url("../images/logo.svg");
      background-position: 50% 50%;
      background-size: 57%;
      background-repeat: no-repeat;
    }

    .modal-language-be-languages-switch-box{
      width: 65%;
      margin: 40px auto 60px auto;

      .language-switch-box{
        width: 100%;
        display: block;
        float: left;
        padding: 0px 10px 0px 10px;
        margin: 20px 0px 20px 0px;

        .belgium-flag-modal{
          display: block;
          float: left;
          background: url("../images/language_flags/belgiumFlag.jpg");
          background-position: 50% 50%;
          background-size: 100% 100%;
          width: 52px;
          height: 36px;

        }

        .modal-country-name{
          float: left;
          display: block;
          padding: 5px 0px 0px 20px;
          font-family: 'titillium_webregular';
          font-size: 18px;
          color: black;

          &:hover{
            color: #79A0D9;
          }
        }
      }
    }
  }
}