@media all and (min-width:767px) and (max-width:1220px){

  .service-template-block,.aboutus-template-block{
    .one-third-content-box{
      .smaller-content-box{
        width: 100%;
        float: left;
      }

      .bigger-content-box{
        width: 100%;
        float: left;

        img{
          padding: 0px 0px 0px 0px;
        }
      }
    }

    .service-block-social-media-block{
      position: absolute;
      left: -35px;
      z-index: 4;
    }

    .service-block-fixed-position{
      position: fixed;
      top: 100px;
      margin-left: -35px;
      left: inherit;
    }

    .cms-top-banner-background-image{
      display: block;
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      height: 350px;
    }
  }
}