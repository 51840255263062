@media all and (max-width:767px){

  .contact-index-index{

    #store_map{
      min-height: 300px !important;
    }

    .contact-locations-container{
      margin-left: -80px;
      margin-right: -80px;
      .location-info-box{
        width: 100%;
        padding: 0 130px;
      }
    }

    .contact-locations-title{
      span{
        border-bottom: 2px solid #6699FF;
        padding-bottom: 6px;
        font-family: 'titillium_webregular';
        color: #00305A;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        padding-right: 20px;
        display: inline-block;
      }
    }

    .contact-more-information-box{
      .left-side-content{
        width: 100%;

        .information-sub-box{
          width: 100%;
          margin: 0px 0px 10px 0px;

          &:first-of-type{
            width: 100%;
          }
        }
      }

      .right-side-content{
        margin: 10px 0px 20px 0px;
        width: 100%;
      }
    }

    .gm-style-iw.gm-style-iw-c {
      height: unset !important;
      max-height: unset !important;
      max-width: 240px !important; // must add !important because it's overwrite weird javascript calc
        .gm-style-iw-d {
          max-height: unset !important;
          max-width: 240px !important;
        }
    }

  }
}