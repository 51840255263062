@media all and (min-width:767px) and (max-width:1220px){

  .column.main {
    > .post-list-body {
      width: 100%;
      margin: 0 0 0 0;

      .post-list-main-container {
        .single-post-box {
          .single-post-content-box {
            width: 70%;
            h2 a {
              max-width: initial;
            }
          }
        }
      }
    }

    .info-banner-container{
      .info-slide{
        .info-content{
          h3{
            font-size: 19px;
          }
        }
      }
    }
  }
}
