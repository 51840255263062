.catalog-category-view,.catalogsearch-result-index{

  .search__result-tabs{
    display: none;
  }

  .morelink{
    color: #6699FF;
  }

  .simple-category-banner{
    margin: 40px 0px 0px 0px;
    img {
      width: 100%;
    }
  }

  .subcategories-list-static-block-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    a {
      text-decoration: none;
    }

    .category-slide{
      border: 2px solid #F3F4F4;
      background-color: #fff;
      padding: 20px 0px 20px 0px;
      min-height: 290px;
      width: 250px;
      margin: 0px 12px 25px 12px;

      .category-image{
        text-align: center;
        width: 150px;
        margin: auto auto;
        position: relative;

        .regular-image{
          position: relative;
          z-index: 4;
          opacity: 1;
          max-height: 100px;
          max-width: 150px;
          margin: auto auto;
        }

        .hover-image{
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: 99;
          opacity: 0;
          max-height: 100px;
          max-width: 150px;
          margin: auto auto;
        }
      }

      .category-content{
        min-height: 77px;

        h3{
          color: #00305A;
          font-family: 'titillium_websemibold';
          font-size: 18px;
          text-align: center;
          text-transform: uppercase;
          min-height: 38px;
        }

        p{
          text-align: center;
          font-family: 'titillium_webregular';
          font-size: 15px;
          line-height: 16px;
          width: 210px;
          margin: 10px auto 10px auto;
          min-height: 77px;
          color: #777777;
        }
      }

      .category-bottom-content{
        text-align: center;

        span {
          height: 30px;
          line-height: 30px;
          display: block;
          width: 150px;
          margin: auto auto;
          color: #6699FF;
        }
      }

      &:hover{
        &.category-slide{
          border: 2px solid #6F90C6;
          cursor: pointer;
        }

        .category-content{
          h3{
            color: #6F90C6;
          }
        }

        .category-bottom-content{
          span {
            background-color: #6699FF;
            color: #fff;
            &:hover {
              transition: all 0.2s ease-in-out;
              background: #003059;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .category-services-list-container{
    .subcategories-list-static-block-container{
      .category-image{
        width: 100px;
      }

      .category-slide{
        &:hover{
          .regular-image{
          opacity: 0;
        }

        .hover-image{
          opacity: 1;
          z-index: 4;
        }
        }
      }
    }
  }

  .block-category-list{
    margin: 0px 0px 40px 0px;

    .block-title {
      font-family: 'titillium_webregular';
      font-weight: 400;
      font-style: normal;
      background-color: #6699FF;
      border-radius: 0px 0px 0px 0px;
      color: #fff;
      line-height: 1.4;
      text-transform: none;
      border: none;
      padding: 10px 5px 10px 20px;
      font-size: 20px;

      strong {
        font-weight: 300 !important;
      }
    }

    .block-content{
      background-color: #fff;
      padding: 0px 0px;
      border: none;

      ol{
        li.item{
          margin: 5px 0px 5px 0px;
          padding: 7px 5px 7px 20px;
          background-color: #FBFBFB;
          line-height: 1;

          a{
            font-size: 17px;
            font-family: 'titillium_webregular';
            color: #00305A;
            font-weight: 300;

            span{
              color: #B0B0B0;
              font-family: 'titillium_weblight';
              font-weight: 500;
              margin: 0px 0px 0px 5px;
            }
          }

          &:hover{
            background-color: #FFF;
            a{
              text-decoration: none;
              color: #6699FF;
            }
          }
        }

        li.active-category-item{
          background-color: #FFF;

          a{
            color: #6699FF;
          }
        }
      }
    }
  }

  .products-grid{
    .product-item{
      min-height: 250px;
      padding: 10px 5px 10px 5px !important;

      .actions-primary{
        width: 120px;
        background-color: #fff;
        margin: auto auto;
        padding: 5px 10px 5px 10px;
        border: 1px solid #6699FF;

        a{
          color: #6699FF;
        }
        &:hover {
          transition: all 0.2s ease-in-out;
          background: #003059;
          a {
            text-decoration: none;
          }
        }
      }

      &:hover{
        -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);

        .actions-primary{
          width: 120px;
          background-color: #6699FF;
          margin: auto auto;
          padding: 5px 10px 5px 10px;
          border: 1px solid #6699FF;

          a{
            color: #fff;
          }
        }
      }

      .product-item-details{
        position: relative !important;
        min-height: 140px;

        .product-sku{
          margin: 0px 0px 20px 0px;
        }

        .product-item-name{
          a{
            &:hover{
              text-decoration: none;
            }
          }
        }
      }

      .product-item-inner{
        margin: 9px 0px 0 0px;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 0;
      }

      .product-item-info{
        .main-product-item-link{
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
        }
      }

    }
  }

  .product-item-photo{
    border: none;
    padding: 0;
      min-height: 185px;
      display: flex;
    .product-label{
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;


      .label-position2,.label-position3{
        top: 8px;
      }
    }
    .product-image-photo {
      width: auto;
    }
  }

  .layered-filter-block-container{

    .filter-options-title{
      border: none;
      background-color: transparent;
      font-size: 16px;
      font-family: 'titillium_webregular';
      color: #00305A;
      text-transform: none;
      border-radius: 0px;
      padding: 10px 15px 10px 15px;
      border-bottom: none;
      text-transform: uppercase;

      &:after{
        display: none;
      }
    }

    .filter-options-item{
      border: 1px solid #E4E4E4;
      border-bottom: none;
      margin-bottom: 0px;

      &:last-of-type{
        border-bottom: 1px solid #E4E4E4;
      }
    }


    .filter-options-item.active{

      .filter-options-title{
        &:after{
          transform: rotate(180deg);
          transition: all 0.3s ease;
        }
      }
    }

    .filter-options-content{
      padding: 0px 15px 15px 15px;
      border: none;
      background-color: transparent;
      border-radius: 0px;
      border-top: none;

      a{
        font-size: 15px;
        font-family: 'titillium_webregular';
        color: #B0B2B2;
      }

      .items-box{
        display: block;
        position: relative;
        left: 24px;
        top: -2px;
        width: calc(100% - 24px);
      }

      .items {
        .item {
          line-height: 1em;

        }
      }
    }

    .filter-actions{
      background: transparent;
      border: none;

      a{
        display: inline-block;
        text-align: center;
        border: 1px solid #00305A;
        font-family: 'titillium_webregular';
        color: #00305A;
        padding: 3px;
        font-size: 15px;
        width: 150px;
      }
    }
  }

  .toolbar-products{
    margin: 20px 0px 0px 0px;

    .pages{
      float: right;
      display: block;

      .page{
        border-radius: 0px;
        border: 1px solid #00305A;

        &:hover{
          background: #00305A;
          color: #fff;
        }
      }

      strong.page{
        background: #00305A;
      }

      a{
        color: #00305A;

        &:before{
          color: #00305A;
        }
      }

      .action{
        border: 1px solid #00305A;
        border-radius: 0px;

        &:hover{
          background: #00305A;

          &:before{
            color: #fff;
          }
        }

        &:before{
          color: #00305A;
        }
      }

      >.label{
        position: initial;
        height: inherit;
        width: inherit;
        margin: 0px 10px 0px 5px;
        font-family: 'titillium_webextralight';
        color: #C8C8C8;
        font-size: 15px;
      }
    }

    .limiter{
      margin-right: 10px;
      margin-left: 30px;

      .label{
        font-family: 'titillium_webregular';
        color: #8D8D8D;
        font-size: 15px;
      }
    }

    select{
      border-radius: 0px;
      border: 1px solid #B6B6B6;
      color: #7F7F7F;
      font-family: 'titillium_webregular';
    }
  }

  .products.wrapper ~ .toolbar .limiter{
    display: none;
  }

  .products.wrapper ~ .toolbar .amount-items-category-page{
    display: none;
  }
}

.catalog-top-banner-background-image{
  &.service-top-banner {
    height: 350px;
  }
  display: block;
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: 50% 50%;
}

.category-description-container{
  margin: 0px 0px 20px 0px;

  h2{
    margin: 0px 0px 10px 0px;
    font-family: 'titillium_websemibold';
    font-size: 26px;
    color: #00305A;
    text-align: left;
    letter-spacing: -1px;
  }

  p, span {
    font-family: 'titillium_webregular';
    font-size: 17px;
    line-height: 23.8px;
    text-align: left;
    margin: 10px auto 0px auto;
  }

  a{
    cursor: pointer;
    color: #6F90C6;
    font-size: 15px;
    font-family: 'titillium_websemibold';
    text-decoration: none;
    margin: 5px 0px 5px 0px;
    display: block;
  }
}

.amount-items-category-page{
  float: left;
  font-family: 'titillium_webregular';
  color: #8D8D8D;
  font-size: 15px;
  line-height: 25px;
}

.limiter{
  float: left;
}

/*CHECKBOXES START*/

.checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #B0B2B2;
}

/*GAP*/

.label-container {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 13px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.label-container a {
  color: #69f !important;
}

.checkmark-active {
  position: absolute;
  top: -3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #B0B2B2;
}

.label-container .checkmark-active {
  background-color: #6699FF;
  border: 1px solid #6699FF;
}

.checkmark-active:after {
  content: "";
  position: absolute;
  display: none;
}

.label-container .checkmark-active:after {
  display: block;
}

.label-container .checkmark-active:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// CHECKBOXES END

.form-general-container{

  #advox-general-form{
    width: 100%;
    background-color: #F5F5F5;
    padding: 0px 0px 1px 0px;

    .checkbox-main-container{
      padding: 0px 0px 10px 0px;
    }

    .checkboxes-required-element-error{
      color: #e02b27 !important;
    }

    h3{
      font-family: 'titillium_webregular';
      color: #00305A;
      padding: 20px 15px 0px 15px;
      margin: 0;
    }

    p{
      padding: 10px 15px 5px 15px;
      font-family: 'titillium_webregular';
      color: #A2A4A4;
    }

    .email-reg-exp-error,.phone-reg-exp-error{
      font-size: 12px;
      color: #e02b27;
      font-family: 'titillium_webregular';
      margin: 5px 0px 0px 0px;
    }

    .file-extension-error-message,.file-size-error-message{
      font-size: 12px;
      color: #e02b27;
      font-family: 'titillium_webregular';
      padding: 0px 0px 0px 15px;
      position: relative;
      bottom: 5px;
    }
    .file-container{
      margin-bottom: 0px !important;
    }

    .checkbox-general-form-error-message{
      margin-top: 0px;
      position: relative;
      display: block;
      top: -2px;
      left: 11px;
      width: 200px;
      font-size: 10px;
      text-align: left;
      color: red;
      &:before {
        content:" *";
      }
    }

    .checkbox-general-form-error-message + .checkmark{
      border: 1px solid red;
    }

    .input-container{
      display: block;
      width: 100%;
      margin-bottom: 8px;
      padding: 0px 15px 0px 15px;
      position: relative;

      input.mage-error{
        border: 1px solid #EF4B57;
      }
    }

    div.mage-error[generated]{
      display: none !important;
    }

    textarea.mage-error{
      border: 1px solid #EF4B57;
    }

    .document-info-icon {
      background-image: url("../images/icons/document_icon.svg");
      display: inline-block;
      width: 15px;
      height: 35px;
      right: 0;
      background-size: 80%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      position: relative;
      margin-top: 5px;
      vertical-align: top;
      line-height: 35px;
      float: left;
    }

    .input-icon-box {
      color: white;
      min-width: 45px;
      text-align: center;
      float: left;
      width: 40px;
      height: 35px;
    }

    input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"], textarea{
      border-radius: 0px;
      border: none;
    }

    input[type="text"],input[type="email"]{
      height: 35px;
      width: calc(100% - 45px);
      font-family: 'titillium_webregular';
    }

    ::placeholder {
      color: #A4A4A4;
      opacity: 1;
    }

    input[type="submit"]{
      width: 87%;
      background-color: #6699FF;
      color: white;
      padding: 14px 20px;
      margin: 8px 0;
      border: none;
      border-radius: 0px;
      cursor: pointer;
      margin: 0px auto 15px auto;
      display: block;
      height: 25px;
      line-height: 2px;
      &:hover {
        transition: all 0.2s ease-in-out;
        background: #003059;
      }
    }

    ._keyfocus *:focus, input:not([disabled]):focus, textarea:not([disabled]):focus, select:not([disabled]):focus{
      box-shadow: none;
    }

    textarea{
      width: 250px;
      margin: auto auto;
      display: block;
      height: 180px;
      width: 87%;
      margin-bottom: 12px;
      margin: 0px 15px 10px 17px;
      font-family: 'titillium_webregular';
    }

    /*CHECKBOXES START*/

    .label-container {
      display: block;
      position: relative;
      padding-left: 26px;
      margin-bottom: 0px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .label-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 0px;
      left: 17px;
      height: 15px;
      width: 15px;
      background-color: #fff;
      border: 1px solid #B0B2B2;
    }

    .label-container:hover input ~ .checkmark {
      background-color: #fff;
    }

    .label-container input:checked ~ .checkmark {
      background-color: #fff;
      border: 1px solid #B0B2B2;
    }

    .label-container input:checked ~ .checkbox-general-form-error-message {
      display: none;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .label-container input:checked ~ .checkmark:after {
      display: block;
    }

    .label-container .checkmark:after {
      left: 5px;
      top: -1px;
      width: 5px;
      height: 14px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .label-container{
      p{
        font-family: 'titillium_webregular';
        color: #A2A4A4;
        font-size: 12px;
        padding: 0px 15px 0px 12px;
        margin-bottom: 0px;
      }
    }

    .inputfile{
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .inputfile + label{
      border: 1px solid #6699FF;
      background-color: #fff;
      padding: 0;
      color: #6699FF;
      max-width: 100%;
      font-size: 1.25rem;
      font-weight: 700;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      margin-left: 2px;
      width: 99%;
      height: 35px;
    }

    .inputfile + label .contact-form-attachment-icon{
      width: 42px;
      height: 33px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: top;
    }

    .inputfile + label .add-file-span{
      width: 50px;
      font-family: 'titillium_webextralight';
      margin: 0px 0px 0px 6px;
      line-height: 34px;
    }

    .add-file-2ndspan {
      display: inline-block;
      height: 35px;
      margin: 5px 0 0 5px;
      overflow: hidden;
      white-space: nowrap;
      line-height: 33px;
      font-size: 14px;
      color: #6699FF;
      vertical-align: top;
      width: 202px;
      text-overflow: ellipsis;
    }

    .add-file-3rdspan{
      display: inline-block;
      height: 35px;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
      color: #9F9F9F;
      margin-top: 0;
      vertical-align: top;
      float: right;
      line-height: 10px;
    }

    .delete-attachment{
      display: none;
      height: 35px;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      line-height: 33px;
      padding: 0px 0px 0px 5px;
      font-size: 14px;
      color: red;
      cursor: pointer;
      vertical-align: top;
      position: absolute;
      right: 15px;
      bottom: 0;
    }

    // CHECKBOXES END

  }

  .product-label{
    width: 100%;
    height: 100%;
  }

}

.catalog-category-view.category-sectors {
  .subcategories-list-static-block-container {
    .category-slide {
      .category-image {
        width: 100% !important;

        .regular-image {
          max-width: 100% !important;
        }
      }
    }
  }
}
