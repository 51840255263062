.page-layout-2columns-right .product-info-main{
  width: 54% !important;
  padding-right: 40px;
}

.page-layout-2columns-right .product.media{
  width: 42% !important;
}

.catalog-product-view{

  .column.main{
    position: relative;
  }

  .sidebar-additional{
    height: 837px;
    position: relative;

    .gallery-placeholder{
      position: absolute;
      bottom: 3px;

      .fotorama__stage{
        border: none;
      }

      .fotorama{
        min-height: 359px;
      }
    }
  }

  .gallery-placeholder-image{
    .fotorama__stage{
      border: none;
    }
  }

  .fotorama__caption{
    display: none !important;
  }

  .fotorama{
    position: relative;
    overflow: inherit;

    .x-icon {
      font-size: 0;
    }

    .fotorama__arr--prev .fotorama__arr__arr:before,.fotorama__arr--next .fotorama__arr__arr:before{
      color: #6699FF;
    }

    .fotorama__fullscreen-icon{
      &:before{
        color: #6699FF;
      }
    }

    &.fotorama--fullscreen {
      .fotorama__fullscreen-icon:before {
        content: "";
        box-shadow: none;
      }
      .x-icon {
        font-size: 36px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translatey(-50%);
        -moz-transform: translateX(-50%) translatey(-50%);
        transform: translateX(-50%) translatey(-50%);
        z-index: -1;
        pointer-events: none;
        color: #6699FF;
      }
      .fotorama__fullscreen-icon {
        outline: none !important;
        box-shadow: none;
        width: 32px;
        height: 32px;
        display: none;
        height: 80px;
        right: 0;
        top: 0;
        width: 80px;
        z-index: 999;
        position: relative;
        float: right;
      }
    }

    .fotorama__thumb__arr.fotorama__thumb__arr--left{
      position: absolute;
      left: -16px;
      top: 381px;
      width: 22px;
      cursor: pointer;
    }

    .fotorama__thumb__arr.fotorama__thumb__arr--right{
      position: absolute;
      right: -6px;
      top: 386px;
      width: 22px;
      cursor: pointer;
    }

    .fotorama__thumb--icon{
      padding-bottom: 30px;
    }
  }

  .fotorama__nav--thumbs .fotorama__thumb{
    border: none !important;
    width: 50px;
  }

  .fotorama__stage.fotorama__shadows--right:after, .fotorama__nav.fotorama__shadows--right:after{
    display: none;
  }

  .fotorama__stage.fotorama__shadows--left:before, .fotorama__nav.fotorama__shadows--left:before{
    display: none;
  }

  .fotorama__nav .fotorama__thumb-border{
    border-radius: 0;
    border-color: #6699FF;
  }

  .fotorama__nav-wrap.fotorama__nav-wrap--horizontal{
    margin: 23px auto 0px auto;
    width: 330px;
  }

  .fotorama--fullscreen {
    .fotorama__nav-wrap.fotorama__nav-wrap--horizontal{
      margin: 0px auto 0px auto;
      width: 100%;
      text-align: center;
    }
  }

  .fotorama__zoom-out.zoom-out-loaded,.fotorama__zoom-in.zoom-in-loaded{
    display: none;
  }

  .product-info-main{
    min-height: 425px;
    position: relative;

    .page-title-wrapper{
      h1.page-title span{
        font-family: 'titillium_webregular';
        font-weight: 500;
        color: #244369;
        font-size: 22px;
        line-height: 1.2;
      }
    }

    .product.overview{
      font-family: 'titillium_webregular';
      font-size: 15px;
    }

    .product-info-stock-sku{
      text-align: left;
      padding: 0px 0px 0px 0px;
      font-family: 'titillium_webregular';
      font-size: 19px;
      line-height: 19px;
      font-weight: 500;
      color: #9B9D9D;

      .type{
        font-weight: 500;
        color: #9B9D9D;

        &:after{
          display: none;
        }
      }
    }

    .download-pdf-specification-button{
      color: #496182;
      font-size: 14px;
      font-family: 'titillium_webregular';
      font-weight: 500;
      margin: 20px 0px 20px 0px;
      cursor: pointer;
      display: block;
      text-decoration: none;

      .download-pdf-specification-button-background{
        display: inline-block;
        width: 24px;
        height: 32px;
        vertical-align: middle;
      }

      .download-pdf-paragraph{
        display: inline-block;
        position: relative;
        top: 2px;
        left: 4px;
        &:hover {
          border-bottom: 1px solid #496182;
        }
      }
    }

    .product.attribute.description{
      margin: 20px 0px 20px 0px;
      font-family: 'titillium_webregular';
      font-size: 14px;
      line-height: 18px;
    }
  }

  #general-form-button {
    width: 230px;
    height: 50px;
    background: #6699FF;
    border: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 16px;
    &:hover {
      transition: all 0.2s ease-in-out;
      background: #003059;
    }

    span{
      color: #fff;
      font-family: 'titillium_webregular';
    }
  }

  #detailed-form-button{
    height: 50px;
    min-width: 160px;
    background: #fff;
    border: 1px solid #5C7C9F;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 16px;

    &:hover {
      span {
        text-decoration: underline;
      }
    }

    span{
      color: #5C7C9F;
      font-family: 'titillium_webregular';
    }

  }

  div#tab-label-advox_customerforms_generalform_index,
  div#tab-label-additional {
    a{
      padding: 0px 25px 0px 25px;
    }
  }

  .product.info.detailed{
    margin-bottom: 40px;

    #advox_customerforms_detailedform_index {
      .grey-background-container{
        background: none !important;
      }
    }
    
    .item.title{
      margin: 1px 0px 0px 0px;

      .switch{
        border-bottom-width: 2px;
        border-top-width: 0px;
        background: none;
        border-radius: 0px 0px 0px 0px;
        border-left: none;
        border-right: none;
        font-family: 'titillium_webregular';
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        padding: 1px 15px 1px 0px;
      }
    }

    #tab-label-advox_customerforms_detailedform_index-title,
    #tab-label-product\.info\.description-title {
      padding: 1px 25px 1px 0px;
    }

    .item.title.active{
      .switch{
        border-bottom-color: #6699FF;
        font-family: 'titillium_webregular';
        color: #00305A !important;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;

          &:hover{
          color: #00305A;
        }
      }
    }
  }

  .product.data.items{
    .item.content{
      box-shadow: none;
      border: none;
      border-top: 2px solid #ededee;
      padding: 20px 0px 0px 0px;
      font-family: 'titillium_webregular';
      font-size: 15px;
    }
  }

  .product-page-forms-buttons{
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 91%;
  }


  #advox-general-form,#advox-detailed-form{
    width: 100%;
    position: relative;

    .general-success-input-border {
      border: 1px solid green;
    }
    .general-error-input-border {
      border: 1px solid #e02b27;
    }

    .file-extension-error-message,.file-size-error-message{
      font-size: 12px;
      color: #e02b27;
      font-family: 'titillium_webregular';
      padding: 0px 0px 0px 15px;
      position: relative;
      bottom: 5px;
    }

    .email-reg-exp-error,.phone-reg-exp-error{
      font-size: 12px;
      color: #e02b27;
      font-family: 'titillium_webregular';
      margin: 5px 0px 0px 0px;
    }

    .checkboxes-required-element-error,.checkboxes-required-element{
      margin: 0px 0px 0px 15px;
    }

    .checkbox-main-container{
      margin: 10px 0px 0px 0px;
      padding: 0px 0px 20px 0px;
      width: 35%;
    }

    .left-side-content{
      width: 35%;
      float: left;
    }

    .right-side-content{
      width: 65%;
      float: left;
      padding: 0px 0px 0px 10px;
    }

    .input-container{
      display: block;
      width: 100%;
      margin-bottom: 8px;
      padding: 0px 20px 0px 15px;
      position: relative;

      div.mage-error[generated]{
        display: none !important;
      }

      input.mage-error{
        border: 1px solid #EF4B57;
      }
    }

    .file-container{
      margin-bottom: 8px;
    }

    .checkbox-general-form-error-message+.checkmark{
      border: 1px solid red;
    }

    .input-icon-box{
      color: white;
      min-width: 44px;
      text-align: center;
      float: left;
      width: 40px;
      height: 35px;
    }

    .document-info-icon{
      background-image: url("../images/icons/document_icon.svg");
      display: inline-block;
      width: 15px;
      background-size: 80%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 35px;
      line-height: 35px;
      vertical-align: top;
      position: relative;
      right: -11px;
    }

    input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"], textarea{
      border-radius: 0px;
      border: none;
      font-family: 'titillium_webregular';
    }

    input[type="text"],input[type="email"],input[type="tel"]{
      height: 35px;
      border: 1px solid #EBEBEB;
      width: calc(100% - 45px);
      font-family: 'titillium_webregular';
    }

    input[type="submit"]{
      width: 180px;
      background-color: #6699FF;
      color: white;
      padding: 14px 20px;
      border: none;
      border-radius: 0px;
      cursor: pointer;
      margin: 57px 20px 15px auto;
      display: block;
      height: 40px;
      line-height: 2px;
      font-family: 'titillium_webregular';
      font-size: 15px;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0px 20px 20px 0px;
      z-index: 9;

      &:hover {
        transition: all 0.2s ease-in-out;
        background: #003059;
      }
    }

    ::placeholder {
      color: #A4A4A4;
      opacity: 1;
      font-family: 'titillium_webregular';
    }

    ._keyfocus *:focus, input:not([disabled]):focus, textarea:not([disabled]):focus, select:not([disabled]):focus{
      box-shadow: none;
    }

    textarea{
      width: 100%;
      display: block;
      height: 113px;
      border: 1px solid #EBEBEB;
    }

    textarea.mage-error{
      border: 1px solid #EF4B57;
    }

    /*CHECKBOXES START*/

    .label-container {
      display: block;
      position: relative;
      padding-left: 26px;
      margin-bottom: 0px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .label-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 0px;
      left: 17px;
      height: 15px;
      width: 15px;
      background-color: #fff;
      border: 1px solid #B0B2B2;
    }

    .label-container:hover input ~ .checkmark {
      background-color: #fff;
    }

    .label-container input:checked ~ .checkmark {
      background-color: #fff;
      border: 1px solid #B0B2B2;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .label-container input:checked ~ .checkmark:after {
      display: block;
    }

    .label-container .checkmark:after {
      left: 5px;
      top: -1px;
      width: 5px;
      height: 14px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .label-container{
      p{
        font-family: 'titillium_webregular';
        color: #A2A4A4;
        font-size: 12px;
        padding: 0px 15px 0px 12px;
        margin-bottom: 0;
      }
    }

    .inputfile{
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .inputfile + label{
      border: 1px solid #6699FF;
      background-color: #fff;
      padding: 0;
      color: #6699FF;
      max-width: 80%;
      font-size: 1.25rem;
      font-weight: 700;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      margin-left: 2px;
      width: 95px;
      height: 35px;
      vertical-align: top;
    }

    .inputfile + label .contact-form-attachment-icon{
      width: 37px;
      height: 33px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: top;
    }

    .inputfile + label .add-file-span{
      width: 50px;
      font-family: 'titillium_webextralight';
      margin: 0px 0px 0px 7px;
      line-height: 32px;
      font-size: 12px;
    }

    .add-file-2ndspan{
      display: inline-block;
      height: 35px;
      margin: 0px 0px 0px 15px;
      overflow: hidden;
      white-space: nowrap;
      line-height: 33px;
      padding: 0px 0px 0px 10px;
      font-size: 14px;
      color: #6699FF;
      vertical-align: top;
    }

    .add-file-3rdspan{
      display: inline-block;
      height: 35px;
      overflow: hidden;
      white-space: nowrap;
      line-height: 33px;
      font-size: 14px;
      color: #9F9F9F;
      vertical-align: top;
    }

    .delete-attachment{
      display: none;
      height: 35px;
      margin: 0px 0px 0px 0px;
      overflow: hidden;
      white-space: nowrap;
      line-height: 33px;
      padding: 0px 0px 0px 5px;
      font-size: 14px;
      color: red;
      cursor: pointer;
    }

    .max-file-size-span{
      font-size: 13px;
      line-height: 27px;
      padding: 0px 0px 0px 15px;
      color: #9F9F9F;
      bottom: 0px;
      position: relative;
      display: inline-block;
      height: 35px;
      line-height: 33px;
      overflow: hidden;
      vertical-align: top;
    }

    .checkbox-general-form-error-message{
      margin-top: 0px !important;
      position: relative !important;
      display: block !important;
      top: -2px !important;
      left: 12px !important;
      width: 200px !important;
      font-size: 10px !important;
      text-align: left !important;
      color: #e02b27 !important;
      &:before {
        content:" *";
      }
    }

    // CHECKBOXES END

    .minus-span-form-input{
      width: 18px;
      border: 1px solid #EBEBEB;
      background-color: white;
      cursor: pointer;
      text-align: center;
      line-height: 33px;
      font-size: 18px;
      height: 35px;
      padding: 3px 2px 6px 2px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      @media all and (min-width:767px) and (max-width:992px){
        padding: 2px 2px 7px 2px;
      }
    }

    .plus-span-form-input{
      width: 18px;
      border: 1px solid #EBEBEB;
      background-color: white;
      cursor: pointer;
      text-align: center;
      line-height: 33px;
      font-size: 18px;
      height: 35px;
      padding: 3px 2px 6px 2px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      @media all and (min-width:767px) and (max-width:992px){
        padding: 2px 2px 7px 2px;
      }
    }

    #counter{
      width: 45px;
      min-width: 45px;
      text-align: left;
      border: 1px solid #EBEBEB;
      height: 35px;
      color: #27466B;
      padding: 0px 10px 0px 10px;
    }

    .quantity-span{
      font-family: 'titillium_webregular';
      line-height: 32px;
      margin: 0px 0px 0px 10px;
    }
  }

  .item-info-form-box{
    padding: 10px 20px 10px 20px;
    margin-bottom: 40px;
    background-color: #F9F9F9;

    .left-side-form-info-content{
      float: left;
      width: 70%;

      .product-image{
        width: 50px;
        height: 50px;
        float: left;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .product-description{
        float: left;
        padding: 0px 12px 0px 12px;
        width: calc(100% - 50px);
        p{
          &:nth-of-type(1){
            font-family: 'titillium_webregular';
            color: #00305A;
            font-weight: 600;
            font-size: 16px;
            margin: 0px 0px 0px 0px;
          }

          &:nth-of-type(2){
            font-family: 'titillium_webregular';
            color: #9EA0A0;
            font-size: 15px;
            font-weight: 400;
            margin: 2px 0px 0px 0px;
          }
        }
      }
    }

    .right-side-form-info-content{
      float: left;
      width: 30%;
      text-align: right;

      .form-info-icon{
        width: 25px;
        height: 25px;
        display: inline-block;
      }

      .form-info-description{
        display: inline-block;
        padding: 0px 5px 0px 12px;

        p{
          &:nth-of-type(1){
            font-family: 'titillium_webregular';
            color: #00305A;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            margin: 0px 0px 0px 0px;
            text-transform: uppercase;
            text-align: right;
          }

          &:nth-of-type(2){
            font-family: 'titillium_webregular';
            color: #9EA0A0;
            font-size: 12px;
            line-height: 10px;
            font-weight: 500;
            color: #00305A;
            margin: 4px 0px 0px 0px;
            text-align: right;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .services-product-addinational-block{
    .product-service-box{
      margin: 35px 0px 35px 0px;
      position: relative;

      .product-service-image{
        position: relative;
        z-index: 4;
        opacity: 1;
        width: 60px;
        height: 60px;
        display: block;
        float: left;
        background-size: 100% !important;
      }

      .product-service-hover-image{
        position: absolute;
        top: 0px;
        z-index: 99;
        opacity: 0;
        width: 60px;
        height: 60px;
        display: block;
        float: left;
        background-size: 100% !important;
      }

      .product-service-description-box{
        width: 75%;
        display: block;
        float: left;
        padding: 0px 0px 0px 10px;

        .product-service-title{
          font-family: 'titillium_webregular';
          font-size: 16px;
          color: #244369;
          font-weight: 500;
          text-transform: uppercase;
          margin: 0px 0px 0px 0px;
          cursor: pointer;
        }

        .product-service-description{
          font-family: 'titillium_webregular';
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
          margin: 0px 0px 0px 0px;
          color: #9EA0A0;
        }
      }

      &:first-of-type{
        margin-top: 0px;
      }

      &:hover{
        cursor: pointer;

        .product-service-title{
          color: #6699FF;
        }

        .product-service-image{
          opacity: 0;
        }

        .product-service-hover-image{
          opacity: 1;
        }
      }
    }
  }


  .block.upsell{
    margin: 36px 0px 60px 0px;

    .title{
      background: none;
      border-bottom: 2px solid #ececed;
      padding: 0px 0px 20px 0px;

      strong{
        font-family: 'titillium_webregular';
        color: #00305A;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        background-color: transparent;
      }
    }

    .item-sku{
      display: block;
      width: 100%;
    }

    .product-item-photo{
      border: none;
      border-radius: 0px;
    }

    .products-upsell{
      margin-top: 10px;
      background-color: #F9F9F9;

      .product.name{
        a{
          white-space: normal;

          .item-name{
            font-family: 'titillium_webregular';
            font-weight: 600;
            color: #244369;
            font-size: 15px;
            line-height: 17px;
            width: 95%;
            margin: auto auto;
            min-height: 68px;
            display: block;
          }

          .item-sku{
            font-family: 'titillium_webregular';
            font-size: 15px;
            line-height: 15px;
            font-weight: 600;
            color: #9B9D9D;
            margin: 18px 0px 0px 0px;
          }
        }
      }
    }

    .owl-controls{
      opacity: 1;
      visibility: visible;
      bottom: -40px;
      top: initial !important;
    }

    .owl-dot span{
      width: 20px;
      height: 20px;
      border-radius: 0px;
      margin: 3px 3px;
      background-color: #D8D9D9;
    }

    .owl-dot.active span{
      background-color: #888888;
    }

    .owl-nav{
      position: relative;
      top: -190px;

        [class*=owl-]{
          font-size: 40px !important;
          color: #B0B2B2;
        }

        .owl-prev{
          left: -37px;
          position: absolute;
        }

        .owl-next{
          right: -37px;
          position: absolute;
        }
    }
  }

  .products-grid{
    .product-item{

      .product-item-info{
        background-color: #fff;
        border: 2px solid #fff;

        &:hover{
          border: 2px solid #6F90C6;
          .product-item-details{
            strong{
              a{
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }


  .gallery-placeholder{

    .fotorama__nav-wrap.fotorama__nav-wrap--horizontal{
      display: none !important;
    }

    .fotorama__stage{
      border: none;
      height: 420px !important;
    }

    .product-video{
      height: 100%;
      top: 0;
    }

    .fotorama__thumb__arr{
      display: none !important;
    }

    .fotorama__fullscreen-icon{
      display: none !important;
    }
  }

   .label-description {
    text-align: center;
  }
}


.service-block-social-media-block{
  position: relative;
  top: 15px;
  left: -42px;
  z-index: 4;
  transform: translate3d(0,0,0);

  .social-icons{
    position: absolute;
    left: -7%;
    width: 30px;
    top: -5px;

    >li{
      margin-bottom: 0px;
      >a{
        margin-left: 0px;
        width: 40px;
        height: 40px;
        background: none;
      }
    }
  }
}

.service-block-fixed-position{
  position: fixed;
  top: 100px;
  margin-left: -120px;
  left: inherit;
}

.fotorama__fullscreen-icon:focus:after, .fotorama__zoom-out:focus:after, .fotorama__zoom-in:focus:after, .fotorama__arr:focus:after, .fotorama__stage__shaft:focus:after, .fotorama__nav__frame--thumb:focus .fotorama__thumb:after, .fotorama__nav__frame--dot:focus .fotorama__dot:after {
  box-shadow: none !important;
}

.fotorama__fullscreen-icon {
  display: block !important;
}
