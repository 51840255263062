@media all and (min-width:767px) and (max-width:1220px){

  .cms-general-form {

    #detailed-form-button {
      font-size: 13px;
      line-height: 13px;
    }

    #general-form-button {
      font-size: 13px;
      line-height: 13px;
    }

    #advox-general-form {
      .inputfile + label {
        width: 100px;
      }
      .add-file-2ndspan {
        width: 120px;
      }
    }

    .item-info-form-box{
      padding: 0px 20px 20px 20px;

      .left-side-form-info-content{
        float: left;
        width: 100%;
      }

      .right-side-form-info-content{
        float: none;
        width: 100%;
        text-align: right;

        .form-info-description{
          text-align: left;
          margin: 20px 0px 0px 0px;
        }
      }
    }

  }
}