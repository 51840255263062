@media all and (min-width:767px) and (max-width:1220px){

  .rotom-form-popup{
    .modal-inner-wrap{
      width: 80%;
      min-width: inherit;
    }

    .customer-name{
      display: block !important;
    }
  }

}