
  .flexslider{
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  // Styles for 1st homepage slider START

  #banner-slider-demo-1{

    .owl-controls{
      opacity: 1;
      visibility: visible;
      bottom: 3%;
      top: initial;
    }

    .owl-nav{
      display: none;
    }

    .owl-dot span{
      width: 20px;
      height: 20px;
      border-radius: 0px;
      margin: 3px 3px;
      background-color: #D8D9D9;
    }

    .owl-dot.active span{
      background-color: #888888;
    }
  }

  // Styles for 1st homepage slider END

  // Styles for Brands Slider START

  #brands-slider-demo-1{
    .owl-dots{ display: none !important; }
  }


  // Styles for Brands Slider END

  .column.main{
    min-height: inherit;
    padding-bottom: inherit;
  }

  // Styles for blog posts container START
  .column.main {
    > .post-list-body {
      width: 48%;
      float: left;
      margin: 0 10px 40px 10px;

      // TODO: use calc to width

      .news-side {
        background-color: #00305A;
      }

      .blog-side {
        background-color: #6699FF;
      }

      .post-list-header-container {
        position: relative;
        height: 50px;

        h1 {
          font-family: 'titillium_websemibold';
          font-size: 25px;
          line-height: 25px;
          padding-left: 20px;
          color: #fff;
          padding-top: 11px;
          text-transform: uppercase;
        }

        a {
          display: block;
          position: absolute;
          right: 15px;
          top: 15px;
          font-family: 'titillium_weblight';
          font-size: 20px;
          line-height: 20px;
          color: #fff;
          text-decoration: none;
          cursor: pointer;
          text-transform: lowercase;
          &:hover {
            border-bottom: 1px solid #fff;
          }
        }
      }

      .post-list-main-container {
        .single-post-box {
          background-color: #fff;
          border: 1px solid #f3f3f3;
          margin: 10px 0px 20px 0px;

          .single-post-image-box {
            width: 30%;
            float: left;
            text-align: center;
            position: relative;
            a {
              display: grid;
            }
          }

          .single-post-content-box {
            position: relative;
            width: 70%;
            float: left;
            height: 160px;

            h2 {
              color: #00305A;
              font-family: 'titillium_websemibold';
              font-size: 18px;
              font-weight: 600;
              padding-left: 20px;
              margin: 25px 10px 10px 0px;

              a {
                color: #00305A;
                max-width: 320px;
                display: block;
                min-height: 21px;
              }
            }

            p {
              font-family: 'titillium_webregular';
              font-size: 14px;
              padding-left: 20px;
              overflow: hidden;
              position: relative;
              line-height: 1.2em;
              text-align: justify;
              margin-right: 15px;
              padding-right: 12px;

              &:before {
                content: '...';
                position: absolute;
                right: 0;
                bottom: 0;
              }

              &:after {
                content: '';
                position: absolute;
                right: 0;
                width: 1em;
                height: 1em;
                margin-top: 0.2em;
                background: #f9f9f9;
              }
              &.short-info {
                font-weight: 700;
                color: #666;
                font-size: 12px;
                margin-top: 20px;
                margin-bottom: 25px;
                .mp-info {
                  color: #6699FF !important;
                }
              }
              &:after,
              &:before {
                background: transparent;
                content: "";
              }
            }

            span {
              display: block;
              position: absolute;
              bottom: 15px;
              right: 15px;
              width: 80px;
              height: 30px;
              text-align: center;

              a {
                margin: 5px 0px 0px 0px;
                display: block;
                color: #fff;
                font-family: 'titillium_webregular';
              }
            }

            .news-span {
              a {
                color: #003459;
                font-weight: 600;
                text-decoration: none;
                white-space: nowrap;
                &:hover {
                  border-bottom: 1px solid #003459;
                }
                &:after {
                  content: ">>";
                  padding-left: 5px;
                }
              }
            }

            .blog-span {
              a {
                color: #3f9bf7;
                font-weight: 600;
                text-decoration: none;
                white-space: nowrap;
                &:hover {
                  border-bottom: 1px solid #3f9bf7;
                }
                &:after {
                  content: ">>";
                  padding-left: 5px;
                }
              }
            }
          }
          &:hover {
            box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.1);
          }
        }
      }
      .news-side-content {
        .single-post-box,
        .single-post-content-box {
          min-height: 162px;
        }
        .single-post-box {
          display: flex;
          align-items: stretch;
          padding: 0 !important;
          height: 162px;
          box-sizing: content-box;

          @media (max-width: 768px) {
            height: auto;
          }

          .single-post-image-box{
            width: 35%;
            max-width: 162px;
            height: 100%;
            flex: 0 0 auto;
            display: block;

            a {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              @media (max-width: 768px) {
                align-items: flex-start;
              }
            }

            img{
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
              top: auto;
              transform: none;
              flex-shrink: 0;
            }
          }

          .single-post-content-box {
            display: block;
            flex: 1 1 auto;
          }
        }
      }

      .blog-side-content {
        .single-post-box,
        .single-post-content-box {
          min-height: 256px;
        }
        .date-box {
          display: none;
        }
        .post-link-title {
          color: #559AFB !important;
        }
        .single-post-box {
          background-color: #F9F9F9;
          border: 0;
          display: flex;
          align-items: stretch;
          padding: 0 !important;
          height: 256px;
          box-sizing: content-box;

          @media (max-width: 768px) {
            height: auto;
          }

          .single-post-image-box{
            width: 35%;
            max-width: 190px;
            height: 100%;
            flex: 0 0 auto;
            display: block;

            a {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              @media (max-width: 768px) {
                align-items: flex-start;
              }
            }

            img{
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
              top: auto;
              transform: none;
              flex-shrink: 0;
            }
          }

          .single-post-content-box {
            display: block;
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  // Styles for blog posts container END


  // Styles for service slider START

  .banner-services-container,.banner-product-container{
    position: relative;
    padding: 50px 0px 50px 0px;

    a{
      text-decoration: none;
    }

    .title-box{
      margin: 0px 0px 50px 0px;

      h2{
        margin: 0px 0px 10px 0px;
        font-family: 'titillium_websemibold';
        font-size: 35px;
        color: #00305A;
        text-align: center;
        letter-spacing: 0px;
        text-transform: uppercase;
      }

      p{
        margin: 0px 0px 0px 0px;
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.3;
      }
    }

    &.banner-services-container{
      .owl-controls{
        top: 50px !important;
      }
    }

    .services-slider,.products-slider{
      .service-slide,.product-slide{
        width: 250px;
        margin: auto auto;

        &.product-slide{
          border: 2px solid #F3F4F4;
          background-color: #fff;
          padding: 40px 0px 40px 0px;
          min-height: 290px;

          &:hover{
            border: 2px solid #6F90C6;

            .product-content h3{
              color: #6F90C6;
              text-decoration: none;
            }

            .product-bottom-content{
              span {
                background-color: #6699FF;
                color: #fff;
                display: block;
                transition: all 0.2s ease-in-out;
              }
            }
          }
        }

        &.service-slide{

          p{
            text-transform: none;
          }

          &:hover{
            .service-content h3{
              color: #6F90C6;
              text-decoration: none;
            }

            .service-image{
              .regular-image{
                opacity: 0;
              }

              .hover-image{
                opacity: 1;
              }
            }
          }
        }

        .service-image,.product-image{
          text-align: center;
          width: 150px;
          margin: auto auto;
          position:relative;

          img{
            width: 100%;
          }

          &.service-image{
            width: 100px;
            .regular-image{
              position: relative;
              z-index: 4;
              opacity: 1;
              max-height: 100px;
              max-width: 150px;
              margin: auto auto;
            }

            .hover-image{
              position: absolute;
              top: 0px;
              z-index: 99;
              opacity: 0;
              max-height: 100px;
              max-width: 150px;
              margin: auto auto;
            }
          }

          &.product-image{
            min-height: 76px;
            width: 150px;

            .regular-image{
              position: relative;
              z-index: 4;
              opacity: 1;
              background-size: cover;
              width: 100%;
              height: 135px;
              background-position: 50% 50%;
            }

            .hover-image{
              position: absolute;
              top: 0px;
              z-index: 99;
              opacity: 0;
              background-size: cover;
              width: 100%;
              height: 135px;
              background-position: 50% 50%;
            }
          }
        }

        .service-content,.product-content{

          h3{
            color: #00305A;
            font-family: 'titillium_websemibold';
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
          }

          p{
            text-align: center;
            font-family: 'titillium_webregular';
            font-size: 15px;
            line-height: 16px;
            width: 110px;
            margin: 10px auto 10px auto;
          }

          &.product-content{
            min-height: 80px;
            p{
              min-height: 50px;
              color: #777777;
              width: 90%;
            }

            h3{
              min-height: 38px;
            }
          }

          &.service-content{
            p{
              min-height: 48px;
              color: #777777;
              width: 210px;
            }

            h3{
              min-height: 38px;
            }
          }
        }

        .service-bottom-content{
          text-align: center;
          color: #6699FF;

          a{
            text-decoration: none;
            cursor: pointer;
            color: #6699FF;
          }
        }

        .product-bottom-content{
          text-align: center;

          span {
            height: 30px;
            line-height: 30px;
            display: block;
            width: 150px;
            margin: auto auto;
            color: #6699FF;

            &:hover {
              transition: all 0.2s ease-in-out;
              background: #003059 !important;
            }
          }
        }
      }

        .owl-controls{
          position: absolute;
          top: 130px;
          width: 100%;

        .owl-nav{
          [class*=owl-]{
            font-size: 40px;
            color: #B0B2B2;
          }

          .owl-prev{
            left: -50px;
            position: absolute;
          }

          .owl-next{
            right: -50px;
            position: absolute;
          }
        }
      }
    }

    .service-slider-bottom,.product-slider-bottom{
      text-align: center;
      margin: 30px 0px 0px 0px;

      a{
        font-family: 'titillium_websemibold';
        font-size: 20px;
        text-decoration: none;
        cursor: pointer;
        color: #6699FF;

        &:hover{
          text-decoration: underline;
        }
      }
    }

    .grey-background-container{
      background-color: #F9F9F9;
      height: 100%;
      width: 300%;
      position: absolute;
      left: -100%;
      z-index: -1;
      top: 0px;
    }
  }

  // Styles for service slider END

  // Styles for infobanner START

  .info-banner-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 80px 0px 80px 0px;

    .info-slide{
      width: 220px;

      .info-image{
        text-align: center;
        img{
          width: 80px;
          margin: auto auto;
          height: 80px;
        }
      }

      .info-content{
        h3{
          color: #00305A;
          font-family: 'titillium_websemibold';
          font-size: 22px;
          text-align: center;
          margin-bottom: 20px;

          span{
            display: block;
            width: 100%;
            font-size: 14px;
          }
        }

        p{
          font-family: 'titillium_webregular';
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          width: 150px;
          margin: 10px auto 0px auto;
        }
      }
    }
  }

  .blog-side-content{
    .single-post-content-box{
      .blog-span{
        a{
          color: #6699FF !important;
        }
      }

      .mp-info{
        color: #6699FF !important;
      }

      .post-link-title{
        color: #6699FF !important;
      }
    }
  }
